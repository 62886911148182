import createDecorator from 'final-form-focus';
import { getIn } from 'final-form';

export default createDecorator(undefined, (inputs, errors) => {
  const input = inputs.find((i) => {
    const name = (i.name || i.parentElement.getAttribute('name'));
    return name && getIn(errors, name);
  });
  return input && (input.type === 'radio' ? input.parentElement.parentElement.parentElement : input);
});
