import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

export default graphql(
  gql`
    mutation updateAuth($token: String!) {
      update_auth(token: $token) @client
    }
  `,
)(({ mutate, defaultPath }) => (
  <Route
    exact
    path="/"
    render={({ location }) => {
      let token;
      let referrer;
      const match = location.hash.match(/#access_token=(\S+)&token_type=[b|B]earer&state=(.+)/);
      if (match) {
        [, token, referrer] = match;
        referrer = decodeURIComponent(referrer);
        mutate({ variables: { token } });
      }
      return <Redirect to={referrer || defaultPath} />;
    }}
  />
));
