const inheritedFields = [
  'no_physical_location',
  'address1',
  'address2',
  'cross_street',
  'city',
  'state',
  'zip',
  'emails_attributes',
  'is_partner',
  'is_quality',
  'is_electronically_integrated',
  'has_preferred_access',
  'phone_numbers_attributes',
  'contact_insurances_attributes',
  'non_accepted_contact_insurances_attributes',
  'insurance_network_ids',
  'insurance_notes',
];

export default inheritedFields;
