import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import elasticsearch from '@elastic/elasticsearch';
import _ from 'lodash';

const credentialsQuery = gql`
  query CredentialsQuery {
    credentials: elasticsearch_credentials {
      username
      password
    }
  }
`;

const elasticsearchClient = ({ username, password }) => {
  const url = new URL(process.env.REACT_APP_ELASTICSEARCH_URL);
  if (username) url.username = username;
  if (password) url.password = password;
  return new elasticsearch.Client({
    node: {
      url: new URL(url),
    },
  });
};

class Elasticsearch extends PureComponent {
  state = { searching: false, results: [], total: 0 }

  componentDidMount() {
    if (!this.props.skip) this.handleSearch(this.props);
  }

  async componentDidUpdate(prevProps) {
    if ((!this.state.searching || this.props.allowSimultaneousQueries) && !this.props.skip
      && this.props.refreshIf(prevProps.query, this.props.query, this.state.results)
    ) {
      this.handleSearch(this.props);
    }
  }

  handleSearch = async ({ mode = 'search', unwrap = true, query }) => {
    this.setState({
      searching: true,
      results: [],
      total: 0,
      refresh: () => this.handleSearch({ mode, unwrap, query }),
    });
    const { data: { credentials } } = await this.props.client.query({ query: credentialsQuery });
    elasticsearchClient(credentials).search(query).then(
      (response) => {
        const { body } = response;
        const newState = { searching: false };
        if (mode === 'search') {
          newState.results = unwrap ? body.hits.hits.map((hit) => hit._source) : body.hits.hits;
          newState.total = body.hits.total;
          newState.aggregations = body.aggregations;
        } else {
          const suggestName = Object.keys(query.body.suggest)[0];
          newState.results = body.suggest[suggestName][0].options.map((option) => option.text);
          newState.total = newState.results.length;
        }
        this.setState(newState);
      },
      (error) => {
        console.error(error.message); // eslint-disable-line no-console
        this.setState({ error: error.message, searching: false });
      },
    );
  }

  render() {
    return (
      <>
        {this.props.children(this.state)}
      </>
    );
  }
}

Elasticsearch.propTypes = {
  query: PropTypes.object.isRequired, // eslint-disable-line
  mode: PropTypes.string, // eslint-disable-line
  unwrap: PropTypes.bool, // eslint-disable-line
  children: PropTypes.func.isRequired,
  skip: PropTypes.bool,
  refreshIf: PropTypes.func,
  allowSimultaneousQueries: PropTypes.bool,
};

Elasticsearch.defaultProps = {
  refreshIf: (prevQuery, query) => !_.isEqual(prevQuery, query),
};

export default withApollo(Elasticsearch);
