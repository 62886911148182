import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import PrivateRoute from '../auth/PrivateRoute';
import ContactsPage from '../contact/ContactsPage';
import ContactEditPage from '../contact/ContactEditPage';
import ProviderDirectoryPage from '../provider/ProviderDirectoryPage';
import TasksPage from '../task/TasksPage';
import OAuthCallbackRoute from '../auth/OAuthCallbackRoute';
import './App.css';
import NavBar from './NavBar';

const App = () => (
  <div className="App">
    <BrowserRouter>
      <>
        <OAuthCallbackRoute defaultPath="/contacts" />
        <PrivateRoute path="/" component={NavBar} />
        <Container>
          <PrivateRoute path="/contacts" component={ContactsPage} />
          <PrivateRoute exact path="/contact/:id" component={ContactEditPage} />
          <PrivateRoute exact path="/contact/new/:kind" component={ContactEditPage} />
          <PrivateRoute path="/provider-directory" component={ProviderDirectoryPage} />
        </Container>
        <Switch>
          <PrivateRoute
            path="/tasks/:bucket(mine|waiting|notifications|suggested|all)"
            exact
            component={TasksPage}
          />
          <PrivateRoute
            path="/tasks/:bucket(other)/:id/:title"
            exact
            component={TasksPage}
          />
          <Route path="/tasks" render={() => <Redirect to="/tasks/mine" />} />
        </Switch>
      </>
    </BrowserRouter>
  </div>
);

export default App;
