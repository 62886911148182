import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import classNames from 'classnames';
import './PriorityButton.css';

const updateTaskMutation = gql`
  mutation($id: ID!, $priority: Priority) {
    update_task(input: { id: $id, priority: $priority }) {
      success
      errors
      task { id, priority }
    }
  }
`;

class PriorityButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task: props.task,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps !== this.props) {
      this.setState({ task: newProps.task });
    }
  }

  handleClick = (mutation, task) => {
    mutation({ variables: { id: task.id, priority: task.priority === 'high' ? 'normal' : 'high' } })
      .then(({ data }) => {
        this.setState({ task: data.update_task.task });
      });
  };

  render() {
    const { task } = this.state;
    return (
      <div className={classNames('PriorityButton', this.props.className, { visible: task.priority === 'high' })}>
        <Mutation
          mutation={updateTaskMutation}
          onError={(error) => { console.error('Task update failed:', error); }} // eslint-disable-line no-console
        >
          {(updateTask, { loading }) => (
            <Image
              size="mini"
              label={{
                as: 'a',
                corner: 'right',
                icon: loading ? 'circle notched' : 'flag',
                size: 'mini',
                color: task.priority === 'high' && !loading ? 'red' : null,
              }}
              onClick={() => this.handleClick(updateTask, task)}
            />
          )}
        </Mutation>
      </div>
    );
  }
}

export default PriorityButton;
