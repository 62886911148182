export const login = (referrer) => {
  window.localStorage.clear();
  let pathname = '/';
  let search = '';
  if (referrer) ({ pathname, search } = referrer);
  window.location.href = `${process.env.REACT_APP_AUTH_PROVIDER_URL}/oauth/authorize?`
    + `client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&`
    + `redirect_uri=${window.location.origin}&`
    + 'response_type=token&'
    + `state=${pathname}${search}`;
};

export const logout = () => {
  window.localStorage.clear();
  window.location.href = `${process.env.REACT_APP_AUTH_PROVIDER_URL}/admin/auth/logout`;
};
