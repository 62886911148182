import React, { Component } from 'react';
import { graphql, compose, Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Grid, Message, Loader, Container, Header, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import ContactForm from './ContactForm';
import './ContactEditPage.css';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const inheritable_associations = (include_inherited_data) => (`
      phone_numbers(include_inherited_data: ${include_inherited_data}) {
        id
        kind
        number
        ext
      }
      emails(include_inherited_data: ${include_inherited_data}) {
        id
        kind
        email
      }
      address(include_inherited_data: ${include_inherited_data}) {
        id
        address1
        address2
        cross_street
        city
        state
        zip
      }
      insurance_networks(include_inherited_data: ${include_inherited_data}) { id, name }
      contact_insurances(include_inherited_data: ${include_inherited_data}) {
        id
        insurance_carrier { id, name }
        insurance_types { id, name }
      }
      non_accepted_contact_insurances(include_inherited_data: ${include_inherited_data}) {
        id
        insurance_carrier { id, name }
      }
      insurance_notes
      is_partner(include_inherited_data: ${include_inherited_data})
      has_preferred_access(include_inherited_data: ${include_inherited_data})
      is_electronically_integrated(include_inherited_data: ${include_inherited_data})
      no_physical_location(include_inherited_data: ${include_inherited_data})
      is_quality(include_inherited_data: ${include_inherited_data})`
);

const fragments = {
  contact: gql`
    fragment ContactEditPageContact on Contact {
      id
      created_at
      updated_at
      updated_by {
        id
        display_name
      }
      kind
      status {
        id
      }
      first_name
      first_name
      middle_name
      last_name
      former_name
      nickname
      suffix
      degrees
      languages_spoken
      gender
      company_name
      display_name
      website
      office_hours
      npi
      tax_id
      availability
      expertise
      personality
      custom_warning
      other_notes
      accepting_new_patients
      accepts_insurance
      specialties { id }
      institutions { id }
      diagnostic_groups { id }
      is_complete
      incomplete_reason
      is_internal
      ${inheritable_associations(false)}
      parent {
        id
        company_name
        ${inheritable_associations(true)}
      }
      descendants {
        id
        display_name
      }
    }
  `,
};

const getContactQuery = gql`
  query ContactQuery($id: ID!) {
    contact(id: $id) {
      ...ContactEditPageContact
    }
  }
  ${fragments.contact}
`;

const postLoadFormat = (contact) => {
  const contactInsuranceTransform = (insurances) => (
    (insurances || []).map((insurance) => ({
      id: insurance.id,
      insurance_carrier_id: insurance.insurance_carrier.id,
      insurance_carrier_name: insurance.insurance_carrier.name,
      insurance_carrier: insurance.insurance_carrier,
      insurance_types: (insurance.insurance_types || []),
      insurance_type_ids: (insurance.insurance_types || []).map((it) => it.id),
    }))
  );

  const transformedContact = {
    ..._.pick(
      contact,
      'first_name', 'middle_name', 'last_name', 'former_name', 'nickname', 'suffix', 'degrees', 'gender', 'is_complete',
      'accepting_new_patients', 'accepts_insurance', 'languages_spoken', 'company_name', 'website', 'office_hours',
      'npi', 'tax_id', 'availability', 'expertise', 'personality', 'custom_warning', 'other_notes', 'insurance_notes',
      'incomplete_reason', 'is_internal', 'is_partner', 'is_quality', 'has_preferred_access', 'display_name',
      'is_electronically_integrated', 'no_physical_location', 'parent', 'insurance_networks', 'id',
    ),
    ..._.pick(contact.address, 'address1', 'address2', 'cross_street', 'city', 'state', 'zip'),
    status_id: contact.status.id,
    addressId: contact.address && contact.address.id,
    insurance_network_ids: (contact.insurance_networks || []).map(({ id }) => id),
    specialty_ids: (contact.specialties || []).map(({ id }) => id),
    institution_ids: (contact.institutions || []).map(({ id }) => id),
    diagnostic_group_ids: (contact.diagnostic_groups || []).map(({ id }) => id),
    phone_numbers_attributes: (contact.phone_numbers || [])
      .map(({ id, kind, number, ext }) => ({ id, kind, number, ext })),
    emails_attributes: (contact.emails || []).map(({ id, kind, email }) => ({ id, kind, email })),
    contact_insurances_attributes: contactInsuranceTransform(contact.contact_insurances || []),
    non_accepted_contact_insurances_attributes: contactInsuranceTransform(contact.non_accepted_contact_insurances),
  };

  if (transformedContact.parent) {
    transformedContact.parent
      .contact_insurances = contactInsuranceTransform(contact.parent.contact_insurances);
    transformedContact.parent
      .non_accepted_contact_insurances = contactInsuranceTransform(contact.parent.non_accepted_contact_insurances);
  }

  return transformedContact;
};

const preSaveFormat = (values) => {
  const copy = _.cloneDeep(values);
  copy.phone_numbers_attributes = values.phone_numbers_attributes.filter((attrs) => Object.keys(attrs).length > 0);
  copy.emails_attributes = values.emails_attributes.filter((attrs) => Object.keys(attrs).length > 0);
  copy.contact_insurances_attributes = values.contact_insurances_attributes
    .map(({ id, insurance_carrier_id, insurance_type_ids, _destroy }) => ({
      id,
      insurance_carrier_id,
      insurance_type_ids,
      _destroy,
    }));
  copy.non_accepted_contact_insurances_attributes = values.non_accepted_contact_insurances_attributes
    .map(({ id, insurance_carrier_id, _destroy }) => ({ id, insurance_carrier_id, _destroy }));
  if (values.parent) {
    copy.parent_id = values.parent.id || null;
    delete copy.parent;
  }
  delete copy.descendants;
  return copy;
};

const serverErrorsFormat = (errors) => _.mapKeys(errors, (value, key) => key.replace(/^address\./, ''));

class ContactEditPage extends Component {
  goBack = () => {
    if (this.props.location.search) {
      this.props.history.push({ pathname: '/contacts/search', search: this.props.location.search });
    } else {
      this.props.history.push({ pathname: '/contacts' });
    }
  }

  handleSubmit = async (values) => {
    const mutationName = this.isUpdate() ? 'update_contact' : 'create_contact';
    const variables = { ...this.props.match.params, ...preSaveFormat(values) };
    try {
      const result = await this.props[mutationName]({ variables });
      if (!result.data[mutationName].success) {
        return serverErrorsFormat(result.data[mutationName].errors);
      }
      await sleep(1000); // delay so that ES gets a chance to commit the change
      this.goBack();
      return undefined;
    } catch (err) {
      console.error('handleSubmit failed:', err); // eslint-disable-line no-console
      window.scrollTo(0, 0);
      return { FORM_ERROR: 'Unexpected server error' };
    }
  }

  handleCancel = () => {
    this.goBack();
  }

  isUpdate = () => !!this.props.match.params.id;

  newContact = () => ({
    kind: this.props.match.params.kind,
    status: { id: '1' },
    accepting_new_patients: true,
    accepts_insurance: true,
    no_physical_location: false,
    is_internal: false,
    is_complete: false,
    is_partner: false,
    is_quality: false,
    has_preferred_access: false,
    is_electronically_integrated: false,
  });

  render() {
    return (
      <Container className="ContactEditPage" text>
        <Query
          query={getContactQuery}
          skip={!this.isUpdate()}
          variables={{ id: this.props.match.params.id }}
        >
          {({ loading, data }) => {
            if (loading) return <Loader size="large" active />;

            const contact = this.isUpdate() ? data.contact : this.newContact();
            const contactKindTitle = contact.kind === 'lab_provider' ? 'Diagnostic Facility'
              : contact.kind.charAt(0).toUpperCase() + contact.kind.slice(1);

            return (
              <Grid.Column>
                {!this.isUpdate()
                  && (
                    <Header as="h1">
                      {`New ${contactKindTitle}`}
                    </Header>
                  )}
                {!this.isUpdate() && (contact.kind === 'group') && (
                  <Grid>
                    <Grid.Column width={12}>
                      <Message>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={2} textAlign="center">
                              <Icon name="sitemap" size="huge" />
                            </Grid.Column>
                            <Grid.Column width={14}>
                              <Message.Header>{'What\'s a group?'}</Message.Header>
                              <p>
                                Groups are usually created by health stystem partner managers to organize specialists
                                and practices together. Unlike specialists and practices,
                                <strong> groups cannot be referred to in an order.</strong>
                                <div>
                                  <a
                                    // eslint-disable-next-line max-len
                                    href="https://sites.google.com/onemedical.com/1life-tasks/admin-core-tasks-team/contacts-referrals/orgs-groups"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                  Learn more
                                  </a>
                                </div>
                              </p>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Message>
                    </Grid.Column>
                  </Grid>
                )}
                <>
                  { this.isUpdate() && (
                    <>
                      <p className="timestamp">
                        {`Last updated ${moment(contact.updated_at).fromNow()} by `}
                        { contact.updated_by ? contact.updated_by.display_name : 'an unknown user' }
                      </p>
                      { !contact.is_complete && (
                        <Message warning>
                          <Message.Header>Please Complete this Contact!</Message.Header>
                          { contact.incomplete_reason ? (
                            <>
                              <p>
                                This contact still needs a bit more information.
                                Here are the notes written by previous updaters on what is still needed.
                              </p>
                              <p className="verbatim">
                                { contact.incomplete_reason }
                              </p>
                              <p>
                                Take care of as much as you can and, if you get all of it, mark it complete.
                                Otherwise update the notes with what is still needed. Thanks!
                              </p>
                            </>
                          ) : (
                            <p>This contact needs to be reviewed.</p>
                          )}
                        </Message>
                      )}
                    </>
                  )}
                  <ContactForm
                    initialValues={postLoadFormat(contact)}
                    contactKind={contact.kind}
                    descendants={contact.descendants}
                    onSubmit={this.handleSubmit}
                    onCancel={this.handleCancel}
                  />
                </>
              </Grid.Column>
            );
          }}
        </Query>
      </Container>
    );
  }
}

const gqlFor = (mutation) => gql`
  mutation(
    ${mutation === 'update_contact' ? '$id: ID!' : '$kind: ContactKind!'}
    $status_id: ID
    $first_name: String
    $middle_name: String
    $last_name: String
    $former_name: String
    $nickname: String
    $suffix: String
    $degrees: String
    $languages_spoken: String
    $gender: Gender
    $company_name: String
    $website: String
    $office_hours: String
    $npi: String
    $tax_id: String
    $phone_numbers_attributes: [PhoneNumberAttributes]
    $emails_attributes: [EmailAttributes]
    $addressId: ID
    $address1: String
    $address2: String
    $cross_street: String
    $city: String
    $state: String
    $zip: String
    $availability: Rating
    $expertise: Rating
    $personality: Rating
    $custom_warning: String
    $other_notes: String
    $accepting_new_patients: Boolean
    $accepts_insurance: Boolean
    $specialty_ids: [ID]
    $institution_ids: [ID]
    $diagnostic_group_ids: [ID]
    $insurance_network_ids: [ID]
    $contact_insurances_attributes: [ContactInsuranceAttributes]
    $non_accepted_contact_insurances_attributes: [ContactInsuranceAttributes]
    $insurance_notes: String
    $is_complete: Boolean
    $incomplete_reason: String
    $is_internal: Boolean
    $is_partner: Boolean
    $is_quality: Boolean
    $is_electronically_integrated: Boolean
    $has_preferred_access: Boolean
    $no_physical_location: Boolean
    $parent_id: ID
  ) {
    ${mutation}(
      input: {
        ${mutation === 'update_contact' ? 'id: $id' : 'kind: $kind'}
        status_id: $status_id
        first_name: $first_name
        middle_name: $middle_name
        last_name: $last_name
        former_name: $former_name
        nickname: $nickname
        suffix: $suffix
        degrees: $degrees
        languages_spoken: $languages_spoken
        gender: $gender
        company_name: $company_name
        website: $website
        office_hours: $office_hours
        npi: $npi
        tax_id: $tax_id
        phone_numbers_attributes: $phone_numbers_attributes
        emails_attributes: $emails_attributes
        address_attributes: {
          id: $addressId
          kind: work
          address1: $address1
          address2: $address2
          cross_street: $cross_street
          city: $city
          state_code: $state
          zip: $zip
        }
        availability: $availability
        expertise: $expertise
        personality: $personality
        custom_warning: $custom_warning
        other_notes: $other_notes
        accepting_new_patients: $accepting_new_patients
        accepts_insurance: $accepts_insurance
        specialty_ids: $specialty_ids
        institution_ids: $institution_ids
        diagnostic_group_ids: $diagnostic_group_ids
        insurance_network_ids: $insurance_network_ids
        contact_insurances_attributes: $contact_insurances_attributes
        non_accepted_contact_insurances_attributes: $non_accepted_contact_insurances_attributes
        insurance_notes: $insurance_notes
        is_complete: $is_complete
        incomplete_reason: $incomplete_reason
        is_internal: $is_internal
        is_partner: $is_partner
        is_quality: $is_quality
        is_electronically_integrated: $is_electronically_integrated
        has_preferred_access: $has_preferred_access
        no_physical_location: $no_physical_location
        parent_id: $parent_id
      }
    ) {
      success
      errors
      contact {
        ...ContactEditPageContact
      }
    }
  }
  ${fragments.contact}
`;

export default compose(
  graphql(gqlFor('update_contact'), { name: 'update_contact' }),
  graphql(gqlFor('create_contact'), { name: 'create_contact' }),
)(ContactEditPage);
