import React from 'react';
import { Segment, Header, Dropdown, Menu } from 'semantic-ui-react';
import CreateButton from './CreateButton';
import './TasksListHeader.css';

const TasksListHeader = ({ selectedBucketName, refresh, match, onSort }) => (
  <div className="TasksListHeader">
    { selectedBucketName === 'mine' && (
      <Segment clearing attached>
        <Header floated="left" className="personal-task-header">
          Assigned to Me
        </Header>
        <Header floated="right">
          <Menu compact>
            <CreateButton onSave={refresh} />
          </Menu>
        </Header>
        <Header floated="right">
          <Menu compact className="personal-task-rank-dropdown">
            <div className="personal-task-rank-label">Sort by</div>
            <Dropdown
              inline
              options={[
                { key: 1, text: 'Recently Updated', value: 'score' },
                { key: 2, text: 'Task Category', value: 'display_category' },
              ]}
              defaultValue="score"
              simple
              item
              onChange={(event, data) => onSort(data.value)}
              direction="left"
              data-cy="tasks-sort-by-pull-down"
            />
          </Menu>
        </Header>
      </Segment>
    )}
    { selectedBucketName === 'waiting' && (
      <Segment attached>
        <Header>Waiting</Header>
      </Segment>
    )}
    { selectedBucketName === 'suggested' && (
      <Segment attached>
        <Header>Suggested</Header>
      </Segment>
    )}
    { selectedBucketName === 'all' && (
      <Segment attached>
        <Header>All Open Tasks</Header>
      </Segment>
    )}
    { selectedBucketName === 'notifications' && (
      <Segment attached>
        <Header>Notifications</Header>
      </Segment>
    )}
    { selectedBucketName === 'other' && (
      <Segment attached>
        <Header>{match.params.title.replace('+', ' ')}</Header>
      </Segment>
    )}
  </div>
);

export default TasksListHeader;
