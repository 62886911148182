import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Segment, Table } from 'semantic-ui-react';
import ShowHide from '../components/ShowHide';
import SkippableSubscription from '../components/SkippableSubscription';
import PlaceholderRow from './PlaceholderRow';
import './ScoreBoard.css';

const getProfileQuery = gql`
  query Profile {
    profile {
      id, display_name, day_task_count, week_task_count
    }
  }
`;

const notificationSubscription = gql`
  subscription {
    notification(resource: "Todo", action: UPDATE) {
      payload
    }
  }
`;

const placeholderTable = (
  <Segment className="ScoreBoard" textAlign="left">
    <span>Tasks You&lsquo;ve Completed</span>
    <Table basic="very" size="small">
      <Table.Body>
        <PlaceholderRow />
        <PlaceholderRow />
      </Table.Body>
    </Table>
  </Segment>
);

const Scoreboard = () => (
  <Query query={getProfileQuery}>
    {({ loading, data: { profile }, refetch }) => {
      if (loading) return placeholderTable;
      return (
        <SkippableSubscription
          subscription={notificationSubscription}
          skip={!process.env.REACT_APP_APPSYNC_API_KEY}
          onSubscriptionData={async ({ subscriptionData }) => {
            const todo = JSON.parse(subscriptionData.data.notification.payload);
            if (todo.assignee_id.toString() === profile.id && todo.state === 'finished') refetch();
          }}
        >
          {() => (
            <Segment className="ScoreBoard" textAlign="left">
              <span>Tasks You&lsquo;ve Completed</span>
              <ShowHide active title="">
                <Table basic="very" size="small">
                  <Table.Body>
                    {loading ? <PlaceholderRow />
                      : (
                        <Table.Row>
                          <Table.Cell>Today</Table.Cell>
                          <Table.Cell>{profile.day_task_count}</Table.Cell>
                        </Table.Row>
                      )}
                    {loading ? <PlaceholderRow />
                      : (
                        <Table.Row>
                          <Table.Cell>This week</Table.Cell>
                          <Table.Cell>{profile.week_task_count}</Table.Cell>
                        </Table.Row>
                      )}
                  </Table.Body>
                </Table>
              </ShowHide>
            </Segment>
          )}
        </SkippableSubscription>
      );
    }}
  </Query>
);

export default Scoreboard;
