import React, { Component } from 'react';
import { Grid, Button, Icon } from 'semantic-ui-react';
import { FormSpy } from 'react-final-form';
import ParentModal from './ParentModal';
import IconButton from '../components/IconButton';

class SelectParent extends Component {
  state = { modalOpen: false };

  removeParent = (form) => {
    this.setState({ modalOpen: false });
    form.change('parent', {});
  };

  selectParent = () => {
    this.setState({ modalOpen: true });
  };

  render() {
    const { descendants, contact, disabled } = this.props;

    return (
      <FormSpy>
        {({ form, values: { parent } }) => {
          let parentActionButtons;
          if (parent && Object.keys(parent).length) {
            parentActionButtons = (
              <>
                <Grid.Row className="inheritance-label">
                  Belongs to
                </Grid.Row>
                <Grid.Row>
                  <Button basic type="button">{parent.company_name}</Button>
                  <IconButton icon="pencil" onClick={() => this.selectParent()} disabled={disabled} />
                  <IconButton icon="trash" onClick={() => this.removeParent(form)} disabled={disabled} />
                </Grid.Row>
              </>
            );
          } else {
            parentActionButtons = (
              <>
                <Grid.Row className="inheritance-label">
                  Belongs to
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      type="button"
                      onClick={() => this.selectParent()}
                      disabled={disabled}
                      data-cy="contact-parent-button"
                    >
                      <Icon name="sitemap" />
                      Associate with a Practice or Group
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </>
            );
          }
          return (
            <>
              {parentActionButtons}
              <ParentModal
                descendants={descendants}
                contact={contact}
                modalOpen={this.state.modalOpen}
                onModalClose={() => this.setState({ modalOpen: false })}
              />
            </>
          );
        }}
      </FormSpy>
    );
  }
}

export default SelectParent;
