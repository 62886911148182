import React from 'react';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import Field from './Field';

/* eslint-disable react/jsx-props-no-spreading */
const Dropdown = ({
  name, label, required, inline, validate, format, ...otherProps
}) => (
  <Field {...{
    name, label, required, inline, validate, format,
  }}
  >
    {({ input }) => (
      <SemanticDropdown
        {...input}
        {...otherProps}
        selection
        onChange={(event, data) => input.onChange(data.value)}
      />
    )}
  </Field>
);

export default Dropdown;
