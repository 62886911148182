import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Grid } from 'semantic-ui-react';
import { FormSpy } from 'react-final-form';
import ShowHide from '../components/ShowHide';
import Section from './Section';
import Field from '../components/Field';
import { presence, digits } from './Validators';
import './BasicInformationSection.css';
import SelectParent from './SelectParent';

const getSpecialties = gql`
  query {
    specialties { id, name }
  }
`;

const getDiagnosticGroups = gql`
  query {
    diagnosticGroups: diagnostic_groups { id, name }
  }
`;

const getFeatureFlags = gql`
  query {
    featureFlags: feature_flags
  }
`;

export default ({ contactKind, descendants, contact, isContactAdmin }) => (
  <div className="BasicInformationSection">
    <Section title="Basic Information">
      <FormSpy>
        {({ values: { is_partner } }) => {
          const cannotEditPartner = is_partner && !isContactAdmin;

          return (
            <>
              { contactKind === 'specialist' ? (
                <>
                  <Grid.Row>
                    <Grid.Column>
                      <Field.Input
                        name="first_name"
                        label="First Name"
                        required
                        validate={presence}
                        disabled={cannotEditPartner}
                        data-cy="conact-first-name-input"
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field.Input
                        name="last_name"
                        label="Last Name"
                        required
                        validate={presence}
                        disabled={cannotEditPartner}
                        data-cy="conact-last-name-input"
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <FormSpy>
                    {({ values: { nickname, middle_name, former_name, suffix, company_name } }) => (
                      <ShowHide
                        title="Add more name details"
                        active={nickname || middle_name || former_name || suffix || company_name}
                      >
                        <Grid columns="equal" padded="vertically">
                          <Grid.Row>
                            <Grid.Column>
                              <Field.Input
                                name="nickname"
                                label="Nickname"
                                disabled={cannotEditPartner}
                                data-cy="contact-nickname-input"
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Field.Input
                                name="middle_name"
                                label="Middle Name"
                                disabled={cannotEditPartner}
                                data-cy="contact-middle-name-input"
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column>
                              <Field.Input
                                name="former_name"
                                label="Former Last Name"
                                disabled={cannotEditPartner}
                                data-cy="contact-former-name-input"
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Field.Input
                                name="suffix"
                                label="Suffix"
                                disabled={cannotEditPartner}
                                data-cy="contact-suffix-input"
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={8}>
                              <Field.Input
                                name="company_name"
                                label="Company Name"
                                disabled={cannotEditPartner}
                                data-cy="contact-company-name-input"
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </ShowHide>
                    )}
                  </FormSpy>
                  <Grid.Row>
                    <Grid.Column>
                      <Field.Radio
                        name="gender"
                        label="Gender"
                        options={{ male: 'Male', female: 'Female', non_binary: 'Non-Binary' }}
                        required
                        validate={presence}
                        disabled={cannotEditPartner}
                        data-cy="contact-company-name-input"
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field.Input
                        name="npi"
                        label="NPI"
                        maxLength={10}
                        validate={digits(10)}
                        disabled={cannotEditPartner}
                        data-cy="contact-npi-input"
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Field.Input
                        name="degrees"
                        label="Degrees"
                        disabled={cannotEditPartner}
                        data-cy="contact-degrees-input"
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <FormSpy>
                    {({ values: { languages_spoken } }) => (
                      <ShowHide title="Add languages spoken" active={languages_spoken}>
                        <Grid columns="equal" padded="vertically">
                          <Grid.Row>
                            <Grid.Column>
                              <Field.Input
                                name="languages_spoken"
                                label="Languages Spoken"
                                disabled={cannotEditPartner}
                                data-cy="contact-languages-spoken-input"
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </ShowHide>
                    )}
                  </FormSpy>
                </>
              ) : (
                <>
                  <Grid.Row>
                    <Grid.Column>
                      <Field.Input
                        name="company_name"
                        label="Company Name"
                        required
                        validate={presence}
                        disabled={cannotEditPartner}
                        data-cy="contact-company-name-input"
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field.Input
                        name="npi"
                        label="NPI"
                        maxLength={10}
                        validate={digits(10)}
                        disabled={cannotEditPartner}
                        data-cy="contact-npi-input"
                      />
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
              <Grid.Row>
                <Grid.Column>
                  { contactKind !== 'lab_provider' && (
                    <Query query={getSpecialties}>
                      {({ loading, data: { specialties } }) => (
                        <Field.Dropdown
                          name="specialty_ids"
                          label="Specialties"
                          search
                          multiple
                          openOnFocus={false}
                          required={contactKind !== 'group'}
                          validate={contactKind !== 'group' ? presence : null}
                          loading={loading}
                          options={(specialties || []).map((s) => ({ key: s.id, text: s.name, value: s.id }))}
                          disabled={cannotEditPartner}
                          data-cy="contact-specialties-dropdown"
                        />
                      )}
                    </Query>
                  )}
                  { contactKind === 'lab_provider' && (
                    <Query query={getDiagnosticGroups}>
                      {({ loading, data: { diagnosticGroups } }) => (
                        <Field.Dropdown
                          name="diagnostic_group_ids"
                          label="Diagnostic Groups"
                          search
                          multiple
                          openOnFocus={false}
                          required
                          validate={presence}
                          loading={loading}
                          options={(diagnosticGroups || []).map((s) => ({ key: s.id, text: s.name, value: s.id }))}
                          disabled={cannotEditPartner}
                          data-cy="contact-diagnostic-groups-dropdown"

                        />
                      )}
                    </Query>
                  )}
                </Grid.Column>
              </Grid.Row>
              <Query query={getFeatureFlags}>
                {({ data: { featureFlags } }) => (
                  (featureFlags && featureFlags.includes('admin_orgs_groups'))
                    ? (
                      <Field name="parent">
                        {() => (
                          <SelectParent
                            descendants={descendants}
                            contact={contact}
                            disabled={cannotEditPartner}
                          />
                        )}
                      </Field>
                    ) : null
                )}
              </Query>
            </>
          );
        }}
      </FormSpy>
    </Section>
  </div>
);
