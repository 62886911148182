import React from 'react';
import PropTypes from 'prop-types';
import { Popup, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './InheritanceLockIcon.css';

const InheritanceLockIcon = ({ parent, popupHorizontalOffset, popupVerticalOffset }) => {
  const lockIcon = (
    <Link to={`/contact/${parent.id}`}>
      <Icon name="lock" className="InheritanceLockIcon" />
    </Link>
  );

  return (
    <Popup
      inverted
      position="right center"
      trigger={lockIcon}
      content={`Inherited from ${parent.company_name}`}
      verticalOffset={popupVerticalOffset}
      horizontalOffset={popupHorizontalOffset}
    />
  );
};

InheritanceLockIcon.propTypes = {
  parent: PropTypes.object.isRequired, // eslint-disable-line
  popupVerticalOffset: PropTypes.number,
  popupHorizontalOffset: PropTypes.number,
};

export default InheritanceLockIcon;
