import React from 'react';
import { Label } from 'semantic-ui-react';
import moment from 'moment';
import './ActivityLabel.css';

const ActivityLabel = ({ task, tabName }) => (
  <span className="ActivityLabel">
    {task.state === 'waiting' && `Set to waiting ${moment(task.state_transitioned_at).fromNow(true)} ago`}
    {task.state === 'deferred' && `Deferred until ${moment(task.defer_until).format('MMMM Do')}`}
    {(task.state !== 'started' && tabName === 'mine') && (
      <>
        <Label circular color="teal" size="mini" empty />
        <span>{`Updated ${moment(task.state_transitioned_at).fromNow(true)} ago`}</span>
      </>
    )}
    {((task.state === 'started' && tabName === 'mine')
      || (['started', 'queued', 'assigned'].includes(task.state) && tabName !== 'mine'))
      && `Last activity ${moment(task.state_transitioned_at).fromNow(true)} ago`}
  </span>
);

export default ActivityLabel;
