import React from 'react';
import { Icon } from 'semantic-ui-react';
import './ContactIcon.css';

export default ({ kind }) => (
  <span className="ContactIcon">
    { kind === 'specialist' && <Icon circular inverted color="green" name="user outline" /> }
    { kind === 'practice' && <Icon circular inverted color="teal" name="building outline" /> }
    { kind === 'lab_provider' && <Icon circular inverted color="purple" name="lab" /> }
    { kind === 'group' && <Icon circular inverted color="grey" name="sitemap" /> }
  </span>
);
