const taskCategories = Object.freeze({
  BILLING: 1,
  CONVERSATION: 5,
  CONSULT_ORDER: 19,
  PROCEDURE_ORDER: 23,
  TEST_ORDER: 24,
  MEDICAL_RECORDS_REQUEST: 26,
  RX_RENEWAL_FAX: 31,
  RX_RENEWAL_MYONE: 32,
  RX_RENEWAL_PHONE: 33,
  TREAT_ME_NOW: 35,
  TRIAGE_ENCOUNTER: 36,
  RX_RENEWAL_MOBILE: 39,
  RX_PRIOR_AUTH: 44,
  RX_RENEWAL_ERX: 57,
  RX_RENEWAL_FAILED_ROUTING: 59,
  RX_RENEWAL_PATIENT: 63,
  PRINT_AT: 68,
  UPDATE_PATIENT_INSURANCE: 71,
});

export default taskCategories;
