import React from 'react';
import gql from 'graphql-tag';
import qs from 'qs';
import { Query } from 'react-apollo';
import { Dropdown, Button, Icon } from 'semantic-ui-react';
import { Route, Link } from 'react-router-dom';
import SearchResults from './SearchResults';
import SearchBox from '../components/SearchBox';
import './ContactsPage.css';

const getFeatureFlags = gql`
  query {
    featureFlags: feature_flags
  }
`;

const taskPageSize = 10;

const contactsQuery = (location, props) => {
  const { q, page } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const query = {
    index: `${process.env.REACT_APP_ELASTICSEARCH_INDEX_PREFIX || ''}contacts`,
    size: taskPageSize,
    from: page ? (page - 1) * taskPageSize : 0,
    search_type: 'dfs_query_then_fetch',
    body: {
      sort: [
        { is_active: { order: 'desc' } },
        { no_physical_location: { order: 'desc' } },
      ],
      query: {
        bool: {
          must: [{
            multi_match: {
              query: q || '',
              fields: [
                'specialties.name',
                'diagnostic_groups.name',
                'display_name',
                'company_name',
                'contact_insurances.insurance_carrier.name',
                'insurance_networks.name',
              ],
              type: 'cross_fields',
              operator: 'and',
            },
          }],
          should: [],
        },
      },
    },
  };
  if (location) {
    query.body.query.bool.should.push({
      geo_distance: { distance: '125mi', location },
    });
    query.body.sort.push(
      { _geo_distance: { location, order: 'asc', unit: 'mi', distance_type: 'plane' } },
      { _score: { order: 'desc' } },
    );
  } else {
    query.body.sort.push({ _score: { order: 'desc' } });
  }
  return query;
};

const ContactsPage = ({ match, location }) => (
  <div className="ContactsPage">
    <SearchBox
      autoFocus
      route="contacts/search"
      condensed={!match.isExact}
      index="contacts"
      placeholder="specialty, insurance..."
    />
    <Route
      path={`${match.url}/search`}
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <SearchResults mode="contact" query={(geoLocation) => contactsQuery(geoLocation, props)} {...props} />
      )}
    />
    <Dropdown
      className="fab"
      trigger={<Button circular size="huge" color="red" icon="plus" />}
      icon={null}
      pointing="top right"
    >
      <Dropdown.Menu>
        <Query query={getFeatureFlags}>
          {({ data: { featureFlags } }) => (
            (featureFlags && featureFlags.includes('admin_orgs_groups'))
              ? (
                <Dropdown.Item
                  as={Link}
                  to={{ pathname: '/contact/new/group', search: location.search }}
                >
                  <Icon name="sitemap" />
                  New Group
                </Dropdown.Item>
              ) : null
          )}
        </Query>
        <Dropdown.Item
          as={Link}
          to={{ pathname: '/contact/new/practice', search: location.search }}
        >
          <Icon name="building outline" />
          New Practice
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          to={{ pathname: '/contact/new/lab_provider', search: location.search }}
        >
          <Icon name="lab" />
          New Diagnostic Facility
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          to={{ pathname: '/contact/new/specialist', search: location.search }}
        >
          <Icon name="user md" />
          New Specialist
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </div>
);

export default ContactsPage;
