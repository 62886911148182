import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Form, Table, Loader, Icon } from 'semantic-ui-react';
import { Field as FinalField, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import Field from '../components/Field';
import IconButton from '../components/IconButton';
import { presence, phoneNumberFormat, compose } from './Validators';
import InheritedAttribute from './InheritedAttribute';
import InheritanceLockIcon from './InheritanceLockIcon';
import mergeOwnAndInheritedDedupedByKind from './utils';

const getPhoneNumberKindsQuery = gql`
  query PhoneNumberKindsQuery {
    PhoneNumberKind: __type(name: "PhoneNumberKind") {
      enumValues {
        name
      }
    }
  }
`;

const PhoneNumbersTable = ({ isContactAdmin }) => (
  <div className="PhoneNumbersTable">
    <Form.Field>
      <label>Phone Numbers</label>
      <FormSpy>
        {({ form, values }) => {
          const cannotEditPartner = values.is_partner && !isContactAdmin;

          return (
            <InheritedAttribute name="phone_numbers">
              {({ inheritedValue: inheritedPhoneNumbers, parent }) => (
                <Query query={getPhoneNumberKindsQuery}>
                  {({ loading, data: { PhoneNumberKind } }) => {
                    if (loading) return <Loader active />;

                    const allKinds = PhoneNumberKind.enumValues.map((k) => k.name);
                    const allPhoneNumbers = mergeOwnAndInheritedDedupedByKind(
                      allKinds, inheritedPhoneNumbers, values.phone_numbers_attributes,
                    );
                    const remainingKinds = (selected) => allKinds.filter((k) => k === selected
                      || !allPhoneNumbers.find((p) => p.kind === k));

                    return (
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width="5">Type</Table.HeaderCell>
                            <Table.HeaderCell width="5">Number</Table.HeaderCell>
                            <Table.HeaderCell width="5">Ext</Table.HeaderCell>
                            <Table.HeaderCell width="1"></Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          { allPhoneNumbers.length === 0 && (
                            <Table.Row textAlign="center">
                              <Table.Cell colSpan={4}>
                                <i className="notice">No phone numbers</i>
                              </Table.Cell>
                            </Table.Row>
                          )}

                          {(cannotEditPartner ? allPhoneNumbers : (inheritedPhoneNumbers || [])).map((phone_number) => (
                            <Table.Row key={phone_number.id} verticalAlign="top">
                              <Table.Cell className="read-only">{phone_number.kind}</Table.Cell>
                              <Table.Cell>
                                {`(${phone_number.number.slice(0, 3)})
                                ${phone_number.number.slice(3, 6)}-${phone_number.number.slice(6, 10)}`}
                              </Table.Cell>
                              <Table.Cell>{phone_number.ext}</Table.Cell>
                              {parent && (
                                <Table.Cell textAlign="right">
                                  <InheritanceLockIcon parent={parent} popupHorizontalOffset={7} />
                                </Table.Cell>
                              )}
                              {!parent && <Table.Cell textAlign="center"></Table.Cell>}
                            </Table.Row>
                          ))}
                          { !cannotEditPartner && (
                            <>
                              <FieldArray
                                name="phone_numbers_attributes"
                                validate={(toValidate) => {
                                  // field-level validation would be cleaner but is broken in final-form-arrays@1.1.1
                                  // see https://github.com/final-form/react-final-form/issues/382
                                  const errors = toValidate.reduce((acc, entry, index) => {
                                    if (!entry._destroy && Object.values(entry).length) {
                                      acc[index] = {
                                        kind: presence(entry.kind),
                                        number: compose(presence, phoneNumberFormat)(entry.number),
                                      };
                                    }
                                    return acc;
                                  }, []);
                                  return errors.length ? errors : undefined;
                                }}
                              >
                                {({ fields }) => fields.map((name, index) => (
                                  <Table.Row
                                    key={name}
                                    className={fields.value[index]._destroy && 'hidden'}
                                    verticalAlign="top"
                                  >
                                    <Table.Cell>
                                      <Field.Dropdown
                                        name={`${name}.kind`}
                                        placeholder="Select type"
                                        search
                                        selection
                                        openOnFocus={false}
                                        options={remainingKinds(fields.value[index].kind)
                                          .map((k) => ({ key: k, text: k.replace('_', ' '), value: k }))}
                                        data-cy="contact-phone-number-type-dropdown"
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Field.FormattedNumber
                                        name={`${name}.number`}
                                        type="tel"
                                        format="(###) ###-####"
                                        data-cy="contact-phone-number-input"
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Field.Input name={`${name}.ext`} data-cy="contact-phone-number-ext-input" />
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                      <FinalField name={`${name}._destroy`}>
                                        {({ input }) => (
                                          <IconButton
                                            icon="trash"
                                            onClick={() => input.onChange(true)}
                                            data-cy="contact-phone-number-delete-button"
                                          />
                                        )}
                                      </FinalField>
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                              </FieldArray>
                            </>
                          )}
                        </Table.Body>
                        { !cannotEditPartner && (
                          <Table.Footer>
                            <Table.Row>
                              <Table.HeaderCell
                                colSpan={4}
                                selectable
                                disabled={allPhoneNumbers.length === allKinds.length}
                                onClick={() => form.mutators.push('phone_numbers_attributes', {})}
                                data-cy="contact-add-new-phone-number-button"
                              >
                                <Icon name="plus square outline" iconposition="left" />
                                Add a new phone number
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Footer>
                        )}
                      </Table>
                    );
                  }}
                </Query>
              )}
            </InheritedAttribute>
          );
        }}
      </FormSpy>
    </Form.Field>
  </div>
);

export default PhoneNumbersTable;
