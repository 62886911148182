import mixpanel from 'mixpanel-browser';

const enabled = !!process.env.REACT_APP_MIXPANEL_TOKEN;

if (enabled) mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

export default {
  identify: (id) => {
    if (enabled) mixpanel.identify(id);
  },
  track: (name, props) => {
    if (enabled) mixpanel.track(name, { application: 'onelife-admin', ...props });
  },
};
