import React from 'react';
import PropTypes from 'prop-types';
import Case from 'case';
import { Icon } from 'semantic-ui-react';

const collectKeys = (object, condition) => {
  const keys = Object.entries(object).reduce(
    (acc, entry) => { if (condition(entry[1])) acc.push(Case.capital(entry[0])); return acc; },
    [],
  );
  return keys.length === 0 ? null : keys.join(', ');
};

const Ratings = (props) => {
  const good = collectKeys(props, (value) => value > 1);
  const bad = collectKeys(props, (value) => value < 0);

  if (!good && !bad) return null;

  return (
    <>
      { good && (
        <div>
          <Icon name="star" />
          { good }
        </div>
      )}
      {' '}
      { bad && (
        <div>
          <Icon name="thumbs down" />
          { bad }
        </div>
      )}
    </>
  );
};

Ratings.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  availability: PropTypes.number.isRequired,
  expertise: PropTypes.number.isRequired,
  personality: PropTypes.number.isRequired,
};

export default Ratings;
