import React from 'react';
import { Form } from 'semantic-ui-react';
import Field from './Field';

/* eslint-disable react/jsx-props-no-spreading */
const Radio = ({
  name, label, required, inline, validate, format, value, ...otherProps
}) => (
  <Field {... {
    name, label, required, inline, validate, format,
  }}
  >
    {({ input }) => (
      <Form.Group inline tabIndex="0">
        {Object.entries(otherProps.options).map(([option, lbl]) => (
          <Form.Radio
            {...input}
            key={lbl}
            label={lbl}
            checked={(value || input.value) === option}
            value={value || input.value}
            disabled={otherProps.disabled || !!value}
            onChange={() => input.onChange(option)}
          />
        ))}
      </Form.Group>
    )}
  </Field>
);

export default Radio;
