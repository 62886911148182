import usStates from './usStates.json';

export const compose = (...validators) => (value) => (
  validators.reduce((error, validator) => error || validator(value), undefined)
);

export const any = (...validators) => (value) => (
  validators.reduce(
    (error, validator) => (validator(value) ? error : undefined),
    compose(...validators)(value),
  )
);

export const presence = (value) => (
  (!value || value.length === 0) ? "Can't be blank" : undefined
);

export const digits = (length, errorMessage) => (value) => {
  const message = errorMessage || `Should be ${length} digits long`;
  return (value && !value.match(new RegExp(`^[0-9]{${length}}$`)) ? message : undefined);
};

export const emailFormat = (value) => (
  (value && !value.match(/^(((?!\.)[^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,}))$/)) ? 'Invalid email address' : undefined
);

export const phoneNumberFormat = (value) => (
  (value && !value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-.]?([0-9]{4})$/)) ? 'Invalid phone number' : undefined
);

export const taxIdFormat = (value) => (
  (value && !value.match(/^[0-9]{2}-[0-9]{7}$/)) ? 'Not a valid tax ID code' : undefined
);

export const usState = (value) => (
  value && !Object.keys(usStates).includes(value.toUpperCase()) ? 'Invalid state' : undefined
);
