import React, { Component } from 'react';
import { Search } from 'semantic-ui-react';
import _ from 'lodash';
import Elasticsearch from '../components/Elasticsearch';

const assigneeSearchQuery = ({ query }) => ({
  index: `${process.env.REACT_APP_ELASTICSEARCH_INDEX_PREFIX || ''}task_assignees`,
  body: {
    query: {
      match: {
        name: {
          query,
        },
      },
    },
  },
});

class AssigneeSearch extends Component {
  state = {}

  handleSearch= (event) => {
    this.setState({ searchQuery: event.target.value });
  }

  buildSuggestions = (results) => results.map((result) => ({
    key: result.identifier,
    title: result.role === 'Group' ? `${result.name} (Group)` : result.name,
  }));

  suggestionRenderer = ({ title }) => (
    <div dangerouslySetInnerHTML={{ __html: title }} /> // eslint-disable-line react/no-danger
  );

  render() {
    return (
      <Elasticsearch
        query={assigneeSearchQuery({
          query: this.state.searchQuery,
        })}
        skip={!this.state.searchQuery}
        allowSimultaneousQueries
      >
        {({ results, searching }) => (
          <Search
            loading={searching}
            fluid
            placeholder="User or group"
            value={this.state.searchQuery}
            noResultsMessage="No matching user or group"
            onSearchChange={_.throttle(this.handleSearch, 500, { leading: true })}
            results={this.buildSuggestions(results)}
            resultRenderer={this.suggestionRenderer}
            onResultSelect={(e, { result }) => {
              this.setState({ searchQuery: result.title });
              this.props.onResultSelect(result);
            }}
          />
        )}
      </Elasticsearch>
    );
  }
}

export default AssigneeSearch;
