import React from 'react';
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react';
import Field from './Field';

/* eslint-disable react/jsx-props-no-spreading */
const Checkbox = ({
  name, required, inline, validate, format, value, ...otherProps
}) => (
  <Field {... { name, required, inline, validate, format }}>
    {({ input }) => {
      // use passed in value for checkbox if it is not undefined or null, used for inherited values which can be false
      const checkboxValue = (value !== undefined && value !== null) ? value : input.value;

      return (
        <>
          <SemanticCheckbox
            {...otherProps}
            checked={checkboxValue}
            disabled={otherProps.disabled}
            onChange={(event, data) => {
              const isChecked = data.checked;
              input.onChange(isChecked);
              if (otherProps.onChange) otherProps.onChange(isChecked);
            }}
          />
        </>
      );
    }}
  </Field>
);

export default Checkbox;
