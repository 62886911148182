import React from 'react';
import NumberFormat from 'react-number-format';
import Field from './Field';

/* eslint-disable react/jsx-props-no-spreading */
const FormattedNumber = ({
  name, label, required, inline, validate, value, ...otherProps
}) => (
  <Field {... { name, label, required, inline, validate }}>
    {({ input }) => (
      <NumberFormat {...input} {...otherProps} value={value || input.value} disabled={otherProps.disabled || !!value} />
    )}
  </Field>
);

export default FormattedNumber;
