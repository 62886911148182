import React, { Component } from 'react';
import { Search } from 'semantic-ui-react';
import _ from 'lodash';
import Elasticsearch from '../components/Elasticsearch';
import './InternalUserSearch.css';

const internalUserSearchQuery = ({ query }) => ({
  index: `${process.env.REACT_APP_ELASTICSEARCH_INDEX_PREFIX || ''}task_assignees`,
  body: {
    query: {
      bool: {
        should: {
          match: {
            name: {
              query,
            },
          },
        },
        must_not: {
          term: {
            role: 'Group',
          },
        },
      },

    },
  },
});

class InternalUserSearch extends Component {
  state = {}

  handleSearch= (event) => {
    this.setState({ searchQuery: event.target.value });
  }

  buildSuggestions = (results) => results.map((result) => ({
    key: result.identifier,
    title: result.name,
  }));

  suggestionRenderer = ({ title }) => (
    <div dangerouslySetInnerHTML={{ __html: title }} /> // eslint-disable-line react/no-danger
  );

  render() {
    return (
      <Elasticsearch
        query={internalUserSearchQuery({
          query: this.state.searchQuery,
        })}
        skip={!this.state.searchQuery}
        allowSimultaneousQueries
      >
        {({ results, searching }) => (
          <Search
            className="InternalUserSearch"
            loading={searching}
            fluid
            autoFocus
            input={{ icon: 'user outline', iconposition: 'left' }}
            placeholder="Search for a user"
            value={this.state.searchQuery}
            noResultsMessage="No matching user"
            onSearchChange={_.throttle(this.handleSearch, 500, { leading: true })}
            results={this.buildSuggestions(results)}
            resultRenderer={this.suggestionRenderer}
            onResultSelect={(e, { result }) => {
              this.setState({ searchQuery: result.title });
              this.props.onResultSelect(result);
            }}
            selectFirstResult
          />
        )}
      </Elasticsearch>
    );
  }
}

export default InternalUserSearch;
