import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import qs from 'qs';
import { Button, Grid, Segment, Loader, Header, Pagination, Form } from 'semantic-ui-react';
import classNames from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import StateButton from './StateButton';
import PriorityButton from './PriorityButton';
import AssigneeSearch from './AssigneeSearch';
import ShowHide from '../components/ShowHide';
import ActivityLabel from './ActivityLabel';
import taskPageSize from '../data/taskPageSize';
import EmptyTasksList from './EmptyTasksList';
import tags from '../data/tags';
import './TasksList.css';
import '../components/hoverable.css';

const updateTaskMutation = gql`
  mutation($id: ID!, $assignee_ident: String, $tag_ids: [ID]) {
    update_task(input: { id: $id, assignee_ident: $assignee_ident, tag_ids: $tag_ids }) {
      success
      errors
    }
  }
`;

const TasksList = ({
  match, location, history, searching, tasks, total, refresh, buckets,
}) => {
  const selectedBucketName = match.params.bucket;
  const selectedBucket = buckets[selectedBucketName];
  return (
    <div className="TasksList">
      <Grid.Column width={16}>
        { searching && (
          <Segment attached>
            <Loader inline="centered" size="medium" active />
          </Segment>
        )}
        {(!searching && tasks.length !== 0) && tasks.map((task) => (
          <Segment
            attached
            className={classNames({
              'task hover-container': true,
              secondary: task.state === 'waiting',
              tertiary: task.state === 'deferred',
            })}
            key={task.id}
            id={task.id}
          >
            <Grid padded="horizontally">
              <Grid.Row>
                <Grid.Column width={7}>
                  <ActivityLabel task={task} tabName={selectedBucketName} />
                  <Header as="h4">
                    {task.display_category}
                    { task.tags.find((t) => t.id === tags.PHONE) && <FontAwesomeIcon icon={faPhone} /> }
                  </Header>
                  { task.keywords && <div>{task.keywords}</div> }
                </Grid.Column>
                <Grid.Column className="middle-column" width={5}>
                  { task.patient && (
                    <>
                      <a
                        href={task.patient.chart_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {`${task.patient.first_name} ${task.patient.last_name}`}
                      </a>
                      <div>{moment(task.patient.dob).format('MM/DD/YYYY')}</div>
                      { task.patient.primary_insurance && (
                        <div className="truncated">{task.patient.primary_insurance.name}</div>
                      )}
                    </>
                  )}
                </Grid.Column>
                <Grid.Column width={4}>
                  <Grid className="actions" columns={2}>
                    <Grid.Row verticalAlign="middle">
                      <Grid.Column width={14} textAlign="right">
                        <StateButton task={task} />
                      </Grid.Column>
                      <Grid.Column width={2} textAlign="right">
                        <PriorityButton className="hoverable" task={task} />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="condensed">
                      <Grid.Column width={14} textAlign="right">
                        <p className="sla-timestamp">
                          {(task.sla_due_at && (moment(task.sla_due_at).isAfter(moment())))
                            && `Due in ${moment(task.sla_due_at).fromNow(true)}`}
                          <i>
                            {(task.sla_due_at && (moment(task.sla_due_at).isBefore(moment())))
                              && `${moment(task.sla_due_at).from(moment(), true)} past due`}
                          </i>
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <ShowHide title="More" data-cy="tasks-more-expand-dropdown">
                    <Mutation
                      mutation={updateTaskMutation}
                      onError={(error) => {
                        console.error('Task update failed:', error); // eslint-disable-line no-console
                      }}
                      onCompleted={refresh}
                    >
                      {(mutation, { loading }) => {
                        if (loading) return <p>Updating task...</p>;
                        return (
                          <Grid padded="vertically">
                            <Grid.Row>
                              <Grid.Column>
                                <Form>
                                  <Form.Field inline>
                                    <label>Assign to:</label>
                                    <AssigneeSearch
                                      onResultSelect={(result) => {
                                        mutation({ variables: { id: task.id, assignee_ident: result.key } });
                                      }}
                                      data-cy="tasks-assign-to-search-box"
                                    />
                                    { task.tags.find((t) => t.id === tags.PHONE)
                                      ? (
                                        <Button
                                          size="small"
                                          icon="phone"
                                          content="Requires a Call"
                                          color="yellow"
                                          onClick={() => {
                                            mutation({
                                              variables: {
                                                id: task.id,
                                                tag_ids: task.tags.map((t) => t.id).filter((id) => id !== tags.PHONE),
                                              },
                                            });
                                          }}
                                          data-cy="tasks-requires-a-call-btn-yellow"
                                        />
                                      ) : (
                                        <Button
                                          size="small"
                                          icon="phone"
                                          content="Requires a Call"
                                          basic
                                          onClick={() => {
                                            mutation({
                                              variables: {
                                                id: task.id,
                                                tag_ids: task.tags.map((t) => t.id).concat([tags.PHONE]),
                                              },
                                            });
                                          }}
                                          data-cy="tasks-requires-a-call-btn-white"
                                        />
                                      )}
                                  </Form.Field>
                                </Form>
                              </Grid.Column>
                            </Grid.Row>
                            { task.body && (
                              <Grid.Row>
                                <Grid.Column>
                                  {/* eslint-disable-next-line react/no-danger */}
                                  <div dangerouslySetInnerHTML={{ __html: task.body }} />
                                </Grid.Column>
                              </Grid.Row>
                            )}
                          </Grid>
                        );
                      }}
                    </Mutation>
                  </ShowHide>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ))}
        {(!searching && tasks.length === 0)
          && <EmptyTasksList selectedBucketName={selectedBucketName} match={match} />}
      </Grid.Column>
      <Grid>
        { selectedBucket.paginated && total > taskPageSize && (
          <Grid.Row>
            <Grid.Column className="pagination-container">
              <Pagination
                defaultActivePage={qs.parse(location.search, { ignoreQueryPrefix: true }).page || 1}
                totalPages={Math.ceil(total / taskPageSize)}
                onPageChange={(event, { activePage }) => {
                  history.push(`${match.url}?page=${activePage}`);
                }}
                firstItem={null}
                lastItem={null}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </div>
  );
};

export default TasksList;
