import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Grid, Label } from 'semantic-ui-react';
import { FormSpy } from 'react-final-form';
import Case from 'case';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen, faLaptopMedical, faAward } from '@fortawesome/pro-regular-svg-icons';
import Section from './Section';
import Field from '../components/Field';
import InheritedAttribute from './InheritedAttribute';
import InheritanceLockIcon from './InheritanceLockIcon';
import './NotesSection.css';

const getStatuses = gql`
  query {
    statuses: contacts_statuses { id, name }
  }
`;

const getRatings = gql`
  query {
    ratings: __type(name: "Rating") { enumValues { name } }
  }
`;

export default ({ contactKind, isContactAdmin }) => (
  <div className="NotesSection">

    <FormSpy>
      {({ form, values: { is_partner } }) => {
        const cannotEditPartner = is_partner && !isContactAdmin;

        return (
          <Section title="Notes">
            <Grid.Row columns={3}>
              <Grid.Column>
                <Query query={getStatuses}>
                  {({ loading, data: { statuses } }) => (
                    <Field.Dropdown
                      name="status_id"
                      label="Status"
                      inline
                      openOnFocus={false}
                      loading={loading}
                      options={(statuses || []).map((s) => ({ key: s.id, text: s.name, value: s.id }))}
                      disabled={cannotEditPartner}
                    />
                  )}
                </Query>
              </Grid.Column>
              <Grid.Column verticalAlign="middle" textAlign="center">
                <Field.Checkbox
                  name="accepting_new_patients"
                  label="Accepting New Patients"
                  toggle
                  disabled={cannotEditPartner}
                />
              </Grid.Column>
              <Grid.Column verticalAlign="middle" textAlign="right">
                <Field.Checkbox
                  name="is_internal"
                  label={`One Medical ${{
                    specialist: 'Specialist', practice: 'Practice', lab_provider: 'Diagnostic Facility', group: 'Group',
                  }[contactKind]}`}
                  toggle
                  disabled={cannotEditPartner}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Query query={getRatings}>
                {({ loading, data: { ratings } }) => ['availability', 'expertise', 'personality'].map((k) => (
                  <Grid.Column key={k}>
                    <Field.Dropdown
                      name={k}
                      label={Case.title(k)}
                      loading={loading}
                      options={((ratings || {}).enumValues || [])
                        .map(({ name }) => ({ key: name, text: name, value: name }))}
                      disabled={cannotEditPartner}
                    />
                  </Grid.Column>
                ))}
              </Query>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field.Input
                  name="custom_warning"
                  label="Warning"
                  maxLength={40}
                  disabled={cannotEditPartner}
                />
              </Grid.Column>
            </Grid.Row>

            <InheritedAttribute name="is_partner" booleanAttribute>
              {({ disabled, inheritedValue: inheritedPartner, parent }) => (
                <Grid.Row>
                  <Grid.Column className="partner-checkbox">
                    <Field.Checkbox
                      name="is_partner"
                      toggle
                      value={inheritedPartner}
                      disabled={!isContactAdmin || disabled}
                      onChange={(newValue) => {
                        // make all partner benefits false if not a partner
                        if (!newValue) {
                          form.change('is_quality', false);
                          form.change('has_preferred_access', false);
                          form.change('is_electronically_integrated', false);
                        }
                      }}
                    />
                    <Label>Is Health System Partner</Label>
                    {parent && <InheritanceLockIcon parent={parent} />}
                  </Grid.Column>
                </Grid.Row>
              )}
            </InheritedAttribute>
            <InheritedAttribute name="is_partner" booleanAttribute>
              {({ parent, inheritedValue: inheritedPartner }) => (
                <>
                  {(is_partner || inheritedPartner) && (
                    <Grid.Row>
                      <Grid.Column>
                        <div className="partner-benefits-label">
                          <div className="partnership-section-title">Has these benefits</div>
                          {parent && <InheritanceLockIcon parent={parent} />}
                        </div>
                        <InheritedAttribute name="is_quality" booleanAttribute>
                          {({ disabled, inheritedValue: inheritedQuality }) => (
                            <div className="partner-benefits-checkbox-label">
                              <Field.Checkbox
                                className="partner-benefits-checkbox"
                                name="is_quality"
                                value={inheritedQuality}
                                disabled={!isContactAdmin || disabled}
                              />
                              <FontAwesomeIcon icon={faAward} className="partner-benefits-checkbox-icon" />
                              Recognized for Quality
                            </div>
                          )}
                        </InheritedAttribute>
                        <InheritedAttribute name="has_preferred_access" booleanAttribute>
                          {({ disabled, inheritedValue: inheritedAccess }) => (
                            <div className="partner-benefits-checkbox-label">
                              <Field.Checkbox
                                className="partner-benefits-checkbox"
                                name="has_preferred_access"
                                value={inheritedAccess}
                                disabled={!isContactAdmin || disabled}
                              />
                              <FontAwesomeIcon icon={faDoorOpen} className="partner-benefits-checkbox-icon" />
                              Preferred Access
                            </div>
                          )}
                        </InheritedAttribute>
                        <InheritedAttribute name="is_electronically_integrated" booleanAttribute>
                          {({ disabled, inheritedValue: inheritedElectronicIntegration }) => (
                            <div className="partner-benefits-checkbox-label">
                              <Field.Checkbox
                                className="partner-benefits-checkbox"
                                name="is_electronically_integrated"
                                value={inheritedElectronicIntegration}
                                disabled={!isContactAdmin || disabled}
                              />
                              <FontAwesomeIcon icon={faLaptopMedical} className="partner-benefits-checkbox-icon" />
                              Electronically Integrated
                            </div>
                          )}
                        </InheritedAttribute>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </>
              )}
            </InheritedAttribute>

            <Grid.Row>
              <Grid.Column>
                <Field.TextArea
                  name="other_notes"
                  label="Additional Notes"
                  maxLength={2000}
                  disabled={cannotEditPartner}
                />
              </Grid.Column>
            </Grid.Row>
          </Section>
        );
      }}
    </FormSpy>
  </div>
);
