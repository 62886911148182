import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/pro-light-svg-icons';
import './EmptyTasksList.css';

const EmptyTasksList = ({ selectedBucketName, match }) => (
  <Segment attached className="EmptyTasksList">
    <Header textAlign="center">
      <FontAwesomeIcon icon={faTasks} size="3x" color="grey" />
    </Header>
    <div>
      <strong>

        { selectedBucketName === 'mine'
          && (
            <>
              {'You don\'t have any tasks assigned to you.'}
              <div>Help your team out by picking some up from the Suggested list!</div>
            </>
          )}
        { selectedBucketName === 'waiting' && 'You haven\'t set any tasks to waiting.'}
        { selectedBucketName === 'suggested' && 'There are no sugggested tasks for you.'}
        { selectedBucketName === 'all' && 'There are no open tasks.' }
        { selectedBucketName === 'notifications' && 'You don\'t have any task notifications.'}
        { selectedBucketName === 'other'
          && `${match.params.title.replace('+', ' ')} doesn't have any tasks assigned to them.`}
      </strong>
    </div>
  </Segment>
);

export default EmptyTasksList;
