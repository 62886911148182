import React from 'react';
import { Table, Placeholder } from 'semantic-ui-react';

const PlaceholderRow = () => (
  <Table.Row>
    <Table.Cell width={14}>
      <Placeholder>
        <Placeholder.Line length="long" />
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder width={2}>
        <Placeholder.Line length="full" />
      </Placeholder>
    </Table.Cell>
  </Table.Row>
);

export default PlaceholderRow;
