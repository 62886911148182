import React from 'react';
import { Grid, Header, Label, Icon, Image } from 'semantic-ui-react';
import moment from 'moment';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesOctagon, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import './ProviderList.css';

const oneLifeUrl = process.env.REACT_APP_AUTH_PROVIDER_URL;

const pointImageUrlToProd = (imageURL) => imageURL.replace(
  new RegExp('.*/'),
  'https://s3.amazonaws.com/prod_1life_doc_bucket/',
);

const getOffice = gql`
  query getOffices($ids: [Int!]) {
    offices(ids: $ids) {
      id
      name
    }
  }
`;

const panelStatus = (panelState) => {
  switch (panelState) {
    case 'open':
      return (
        <span className={classNames('positive', 'emphasized', 'panel-status')}>
          <Icon name="check" />
          {'  '}
          Open panel
        </span>
      );
    case 'closed':
      return (
        <span className={classNames('warning', 'emphasized', 'panel-status')}>
          <FontAwesomeIcon icon={faTimesOctagon} />
          {'  '}
          Closed panel
        </span>
      );
    case 'waitlist':
      return (
        <span className={classNames('warning', 'emphasized', 'panel-status')}>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          {'  '}
          Panel waitlisted
        </span>
      );
    default:
      return null;
  }
};

const boldHighlight = (highlight) => {
  const bold = (highlight.length ? highlight.replace(/<em>/g, '<strong>').replace(/<\/em>/g, '</strong>') : '');
  return bold;
};

const getKey = prefix => `${prefix}_${new Date().getTime()}`;

export default ({ results, query }) => (
  <Grid padded="vertically" className="ProviderList">
    { results.map((result) => {
      const resultHighlights = result.highlight;
      const resultInfo = result._source;
      const defaultOffice = resultInfo.default_office;
      const panels = resultInfo.active_panels;
      const defaultOfficePanel = panels.find(panel => panel.office_id === defaultOffice.id);
      const additionalPanels = panels.filter(panel => panel.office_id !== defaultOffice.id);

      return (
        <Grid.Row key={resultInfo.id}>
          <Grid.Column>
            <div className="image-cropper">
              {resultInfo.profile_image_url
                && <Image src={pointImageUrlToProd(resultInfo.profile_image_url)} size="large" />}
            </div>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header>
              <Header.Content>
                <span className="truncate">{ resultInfo.sig_name }</span>
              </Header.Content>
            </Header>
            <Grid.Row className="specialty">
              { resultInfo.service_types && resultInfo.service_types
                .map(s => <Label basic key={s.id}>{s.name}</Label>)
              }
              { resultInfo.specialty_types && resultInfo.specialty_types
                .map(s => <Label basic key={s.id}>{s.name}</Label>)
              }
            </Grid.Row>

            {resultInfo.work_schedule && (
              <Grid.Row className="emphasized">
                <a
                  href={`${oneLifeUrl}/admin/providers/${resultInfo.id}/appointments`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon color="blue" name="calendar" />
                  {resultInfo.work_schedule.out && !resultInfo.work_schedule.on_vacation
                      && (
                      <span>
                        Out of office until:
                        {' '}
                      </span>
                      )}
                  {resultInfo.work_schedule.out && resultInfo.work_schedule.on_vacation
                      && (
                      <span>
                        On leave until:
                        {' '}
                      </span>
                      )}
                  {resultInfo.work_schedule && resultInfo.work_schedule.out
                      && (
                      <span>{moment(resultInfo.work_schedule.returning_on_date).format('ddd, MMMM Do YYYY')}</span>
                      )}
                  {resultInfo.work_schedule && resultInfo.work_schedule.comment
                      && (
                      <span>
                        Note:
                        {' '}
                        {resultInfo.work_schedule.comment}
                      </span>
                      )}
                  {resultInfo.work_schedule && !resultInfo.work_schedule.out && (
                    <span>View Appointment Schedule</span>
                  )}
                </a>
              </Grid.Row>
            )}
            <Grid.Row className="office-info">
              <span className="emphasized">
                {defaultOffice && <span>{defaultOffice.name}</span>}
                {defaultOffice && resultInfo.service_area && <span> - </span>}
                {resultInfo.service_area && <span>{resultInfo.service_area.name}</span>}
              </span>
              {defaultOfficePanel && panelStatus(defaultOfficePanel.state)}
              {defaultOffice && defaultOffice.phone && (
                <div>
                  <span className="section-header emphasized">OFFICE</span>
                  <span>
                    {' '}
                    {defaultOffice.phone}
                  </span>
                </div>
              )}
              {resultInfo.address && (
                <div>
                  {`${resultInfo.address.address1}, `}
                  {resultInfo.address.address2 && `${resultInfo.address.address2}`}
                  { resultInfo.address.address2 && ', '}
                  {`${resultInfo.address.city}, ${resultInfo.address.state.short_name}
                    ${resultInfo.address.zip}`}
                </div>
              )}
            </Grid.Row>
            {additionalPanels && additionalPanels.map((panel) => (
              <Query
                query={getOffice}
                variables={{ ids: [panel.office_id] }}
                key={panel.id}
              >
                {({ loading, data }) => {
                  if (loading || !data) return null;
                  const officeName = data.offices.length && data.offices[0].name;
                  return (
                    <Grid.Row>
                      <span className="emphasized">
                        {officeName}
                      </span>
                      {panelStatus(panel.state)}
                    </Grid.Row>
                  );
                }}
              </Query>
            ))}
          </Grid.Column>
          <Grid.Column width={7}>
            <div className="emphasized section-header">
              EXPERTISE
            </div>
            <div>
              { resultInfo.expertise_types && resultInfo.expertise_types
                .map((s, index) => (
                  <span key={s.id}>
                    {s.name.toLowerCase().includes(query.toLowerCase()) ? (<strong>{s.name}</strong>) : s.name }
                    {resultInfo.expertise_types.length - 1 !== index && ', '}
                  </span>
                ))
              }
            </div>
            { (resultInfo.specialized_procedures_performed.length > 0 || resultHighlights) && (
              <div>
                <div className="emphasized procedures-header section-header">
                  PROCEDURES
                </div>
                <div>
                  { resultInfo.specialized_procedures_performed
                    .map((s, index) => (
                      <span key={s.id}>
                        {s.lay_description.toLowerCase().includes(query.toLowerCase())
                          ? (<strong>{s.lay_description}</strong>) : s.lay_description }
                        {((resultInfo.specialized_procedures_performed.length - 1 !== index) || resultHighlights)
                          && ', '}
                      </span>
                    ))
                  }
                  {resultHighlights && (
                    resultHighlights['essential_procedures_performed.lay_description'].map((highlight, index) => (
                      <>
                        {/* eslint-disable-next-line react/no-danger */}
                        <span key={getKey(highlight)} dangerouslySetInnerHTML={{ __html: boldHighlight(highlight) }} />
                        {resultHighlights['essential_procedures_performed.lay_description'].length - 1 !== index
                          && ', '}
                      </>
                    ))
                  )}
                </div>
              </div>
            )}
            { resultInfo.essential_procedures_not_performed.length > 0 && (
            <div>
              <div className="emphasized procedures-header section-header non-performed">
                NOT PERFORMED
              </div>
              <div>
                { resultInfo.essential_procedures_not_performed
                  .map((s, index) => (
                    <span key={s.id}>
                      {s.lay_description.toLowerCase().includes(query.toLowerCase())
                        ? (<strong>{s.lay_description}</strong>) : s.lay_description }
                      {resultInfo.essential_procedures_not_performed.length - 1 !== index && ', '}
                    </span>
                  ))
                }
              </div>
            </div>
            )}
            <div>
              Updated
              {' '}
              {moment(resultInfo.updated_at).fromNow()}
            </div>
          </Grid.Column>
        </Grid.Row>
      );
    })}
  </Grid>
);
