import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Form, Table, Loader, Popup, Icon } from 'semantic-ui-react';
import { Field as FinalField, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import Field from '../components/Field';
import IconButton from '../components/IconButton';
import { presence, emailFormat, compose } from './Validators';
import InheritanceLockIcon from './InheritanceLockIcon';
import mergeOwnAndInheritedDedupedByKind from './utils';

const directMessageWarning = 'Direct Message is a secure healthcare message protocol for referrals and patient '
                            + 'information, and does not work with standard email addresses.  Only enter a Direct '
                            + 'Message address if you have verified the contact info with the specialist\'s office.';
const getEmailKindsQuery = gql`
  query EmailKindsQuery {
    EmailKind: __type(name: "EmailKind") {
      enumValues {
        name
      }
    }
  }
`;

class DirectMessageWarningPopup extends React.Component {
  state = { isOpen: false };

  handleOpen = () => {
    if (this.props.kind === 'direct_message') {
      this.setState({ isOpen: true });
    }
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <Popup
        wide
        position="top left"
        content={directMessageWarning}
        trigger={this.props.trigger}
        on="focus"
        open={this.state.isOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
      />
    );
  }
}

const EmailAddressesTable = ({ disabled, inheritedEmails, parent }) => (
  <Form.Field>
    <label>Email Addresses</label>
    <FormSpy>
      {({ form, values }) => (
        <Query query={getEmailKindsQuery}>
          {({ loading, data: { EmailKind } }) => {
            if (loading) return <Loader active />;

            const allKinds = EmailKind.enumValues.map((k) => k.name);
            const allEmails = mergeOwnAndInheritedDedupedByKind(allKinds, inheritedEmails, values.emails_attributes);
            const remainingKinds = (selected) => allKinds.filter((k) => k === selected
              || !allEmails.find((e) => e.kind === k));

            return (
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="5">Type</Table.HeaderCell>
                    <Table.HeaderCell width="10">Address</Table.HeaderCell>
                    <Table.HeaderCell width="1"></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { allEmails.length === 0 && (
                    <Table.Row textAlign="center">
                      <Table.Cell colSpan={4}>
                        <i className="notice">No email addresses</i>
                      </Table.Cell>
                    </Table.Row>
                  )}
                  { (disabled ? allEmails : (inheritedEmails || [])).map((email) => (
                    <Table.Row key={email.id} verticalAlign="top">
                      <Table.Cell>{email.kind}</Table.Cell>
                      <Table.Cell>{email.email}</Table.Cell>
                      <Table.Cell textAlign="right">
                        {parent && (
                          <InheritanceLockIcon
                            parent={parent}
                            popupHorizontalOffset={7}
                          />
                        )}
                        {!parent && <Table.Cell textAlign="center"></Table.Cell>}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  { !disabled && (
                    <FieldArray
                      name="emails_attributes"
                      validate={(toValidate) => {
                        // field-level validation would be cleaner but is broken in final-form-arrays@1.1.1
                        // see https://github.com/final-form/react-final-form/issues/382
                        const errors = toValidate.reduce((acc, entry, index) => {
                          if (!entry._destroy && Object.values(entry).length) {
                            acc[index] = {
                              kind: presence(entry.kind),
                              email: compose(presence, emailFormat)(entry.email),
                            };
                          }
                          return acc;
                        }, []);
                        return errors.length ? errors : undefined;
                      }}
                    >
                      {({ fields }) => fields.map((name, index) => (
                        <Table.Row
                          key={name}
                          className={fields.value[index]._destroy && 'hidden'}
                          verticalAlign="top"
                        >
                          <Table.Cell>
                            <Field.Dropdown
                              name={`${name}.kind`}
                              placeholder="Select type"
                              search
                              selection
                              openOnFocus={false}
                              options={remainingKinds(fields.value[index].kind)
                                .map((k) => ({ key: k, text: k, value: k }))}
                              data-cy="contact-email-type-dropdown"
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <DirectMessageWarningPopup
                              trigger={(
                                <Field.Input
                                  name={`${name}.email`}
                                  type="email"
                                  data-cy="contact-email-input"
                                />
                              )}
                              kind={fields.value[index].kind}
                            />
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <FinalField name={`${name}._destroy`}>
                              {({ input }) => (
                                <IconButton
                                  icon="trash"
                                  onClick={() => input.onChange(true)}
                                  data-cy="contact-email-delete-button"
                                />
                              )}
                            </FinalField>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </FieldArray>
                  )}
                </Table.Body>
                { !disabled && (
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell
                        colSpan={4}
                        selectable
                        disabled={allEmails.length === allKinds.length}
                        onClick={() => form.mutators.push('emails_attributes', {})}
                        data-cy="contact-email-add-button"
                      >
                        <Icon name="plus square outline" iconposition="left" />
                        Add a new email
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                )}
              </Table>
            );
          }}
        </Query>
      )}
    </FormSpy>
  </Form.Field>
);

export default EmailAddressesTable;
