import React, { Component } from 'react';
import PropTypes from 'prop-types';

const GeocoderService = window.google && new window.google.maps.Geocoder();

class Geocoder extends Component {
  state = { processing: true }

  componentWillMount() {
    if (this.props.skip) {
      this.setState({ processing: false });
    } else {
      this.process(this.props);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.address === this.props.address || newProps.skip) return;
    this.setState({ processing: true });
    this.process(newProps);
  }

  process = ({ address }) => {
    if (address) {
      GeocoderService.geocode({ address }, (results, status) => {
        if (status !== 'OK') {
          console.error(new Error('Google Geocoder request failure')); // eslint-disable-line no-console
          return;
        }
        const { location } = results[0].geometry;
        this.setState({
          processing: false,
          location: { lat: location.lat(), lon: location.lng() },
        });
      });
    } else {
      this.setState({
        processing: false,
        location: null,
      });
    }
  }

  render() {
    return (
      <>
        {this.props.children(this.state)}
      </>
    );
  }
}

Geocoder.propTypes = {
  address: PropTypes.string, // eslint-disable-line
  children: PropTypes.func.isRequired,
  skip: PropTypes.bool, // eslint-disable-line
};

export default Geocoder;
