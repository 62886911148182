import React from 'react';
import qs from 'qs';
import { Route } from 'react-router-dom';
import SearchResults from './SearchResults';
import SearchBox from '../components/SearchBox';

const taskPageSize = 10;
const suggestContexts = {
  preferred_role: ['Provider'],
};

const providerQuery = (location, props) => {
  const { q, page } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const query = {
    index: `${process.env.REACT_APP_ELASTICSEARCH_INDEX_PREFIX || ''}internal_users`,
    size: taskPageSize,
    from: page ? (page - 1) * taskPageSize : 0,
    search_type: 'dfs_query_then_fetch',
    body: {
      sort: [],
      query: {
        bool: {
          filter: { term: { preferred_role: 'Provider' } },
          must: [{
            multi_match: {
              query: q || '',
              fields: [
                'expertise_types.name',
                'specialty_types.name',
                'display_name',
                'specialized_procedures_performed.clinical_description',
                'specialized_procedures_performed.lay_description',
                'specialized_procedures_performed.abbreviation',
                'essential_procedures_performed.clinical_description',
                'essential_procedures_performed.lay_description',
                'essential_procedures_performed.abbrevation',
              ],
              type: 'cross_fields',
              operator: 'and',
            },
          }],
          must_not: [{
            multi_match: {
              query: q || '',
              fields: [
                'essential_procedures_not_performed',
              ],
            },
          }],
          should: [],
        },
      },
      highlight: {
        fields: {
          'essential_procedures_performed.lay_description': {},
        },
      },
    },
  };
  if (location) {
    query.body.query.bool.should.push({
      geo_distance: { distance: '125mi', location },
    });
    query.body.sort.push(
      { _geo_distance: { location, order: 'asc', unit: 'mi', distance_type: 'plane' } },
      { _score: { order: 'desc' } },
    );
  }
  return query;
};

const ProviderDirectory = ({ match }) => (
  <div className="ProviderDirectoryPage">
    <SearchBox
      autoFocus
      index="internal_users"
      route="provider-directory/search"
      condensed={!match.isExact}
      suggestContexts={suggestContexts}
      placeholder="name, expertise, role, specialties, procedures"
    />
    <Route
      path={`${match.url}/search`}
      render={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <SearchResults query={(location) => providerQuery(location, props)} {...props} />
      )}
    />
  </div>
);

export default ProviderDirectory;
