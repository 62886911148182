import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-state';
import { setContext } from 'apollo-link-context';
import AWSAppSyncClient, { createAppSyncLink, createLinkWithCache } from 'aws-appsync';
import * as serviceWorker from './serviceWorker';
import { logout } from './auth';
import App from './app/App';
import './index.css';

const getAuth = gql`
  query {
    auth @client {
      token
      is_authenticated
    }
  }
`;

const client = new AWSAppSyncClient({
  disableOffline: true,
}, {
  link: ApolloLink.from([
    createLinkWithCache((cache) => {
      cache.writeData(
        {
          data: {
            auth: { __typename: 'Auth', token: null, is_authenticated: false },
          },
        },
      );
      return withClientState({
        cache,
        resolvers: {
          Query: {
            auth: (_, variables, { cache: apolloCache }) => apolloCache.readQuery({ query: getAuth }).auth,
          },
          Mutation: {
            update_auth: (_, { token }, { cache: apolloCache }) => {
              const data = { auth: { __typename: 'Auth', token, is_authenticated: true } };
              apolloCache.writeData({ data });
              return data;
            },
          },
        },
      });
    }),
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => console.error( // eslint-disable-line no-console
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ));
      }
      if (networkError) {
        if (networkError.statusCode === 401) {
          logout();
        } else {
          console.error( // eslint-disable-line no-console
            `[Network error]: Status code: ${networkError.statusCode}, Message: ${networkError.message}`,
          );
        }
      }
    }),
    setContext((request, previousContext) => {
      const { token } = previousContext.cache.readQuery({ query: getAuth }).auth;
      const authorization = token ? `Bearer ${token}` : null;
      return {
        headers: {
          ...previousContext.headers,
          authorization,
        },
      };
    }),
    createAppSyncLink({
      url: process.env.REACT_APP_GRAPHQL_API_URL,
      region: 'us-east-1',
      auth: {
        type: 'API_KEY',
        apiKey: process.env.REACT_APP_APPSYNC_API_KEY,
      },
    }),
  ]),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root'),
);

serviceWorker.register();
