import React, { Component } from 'react';
import PropTypes from 'prop-types';
import repl from 'string-replace-slices-array';

const GooglePlacesService = window.google && new window.google.maps.places.AutocompleteService();

class GooglePlaces extends Component {
  state = { searching: false, results: [] }

  componentWillReceiveProps(newProps) {
    if (!newProps.input || newProps.input === this.props.input || newProps.skip) return;
    this.handleSearch(newProps);
  }

  handleSearch = ({ input }) => {
    this.setState({ searching: true });
    GooglePlacesService.getPlacePredictions(
      { input, componentRestrictions: { country: 'USA' } },
      (predictions, status) => {
        if (status !== 'OK' && status !== 'ZERO_RESULTS') {
          console.error(new Error(`Google Places request failure: ${status}`)); // eslint-disable-line no-console
          return;
        }
        const results = (predictions || []).map((prediction) => {
          const countrylessDescription = prediction.description.replace(/, USA$/, '');
          return {
            placeid: prediction.place_id,
            description: countrylessDescription,
            highlight: repl(countrylessDescription, prediction.matched_substrings
              .map((match) => {
                const indexStart = match.offset;
                const indexEnd = indexStart + match.length;
                return [
                  indexStart,
                  indexEnd,
                  `<em>${countrylessDescription.substring(indexStart, indexEnd)}</em>`,
                ];
              })),
          };
        });
        this.setState({ searching: false, results });
      },
    );
  }

  render() {
    return (
      <>
        {this.props.children(this.state)}
      </>
    );
  }
}

GooglePlaces.propTypes = {
  input: PropTypes.string,
  children: PropTypes.func.isRequired,
  skip: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
};

export default GooglePlaces;
