import React from 'react';
import { List, Grid, Header, Icon, Label, Segment } from 'semantic-ui-react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { format } from 'libphonenumber-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen, faLaptopMedical, faAward } from '@fortawesome/pro-regular-svg-icons';
import Case from 'case';
import moment from 'moment';
import Ratings from './Ratings';
import ContactIcon from './ContactIcon';

const isInactive = (status) => status && status.id !== 1;
const warningsField = (source) => (
  <div className="warning relaxed">
    <Icon name="warning sign" />
    { isInactive(source.status) ? source.status.name : source.custom_warning }
  </div>
);

export default ({ results, search }) => (
  <List divided relaxed>
    { results.map((result) => (
      <List.Item key={result.id}>
        <List.Content className={classNames({ result: true, inactive: !result.is_active })}>
          <Grid>
            <Grid.Column className="contact-icon">
              <ContactIcon kind={result.kind} />
            </Grid.Column>
            <Grid.Column width={7}>
              <Link to={{ pathname: `/contact/${result.id}`, search }}>
                <Header>
                  <Header.Content>
                    <span className="truncate">{ result.display_name }</span>
                    { result.is_internal
                      && <img className="inline-logo" src="/images/logo-small.png" alt="" />}
                    {result.is_partner && (
                      <Icon name="handshake" />
                    )}
                  </Header.Content>
                </Header>
              </Link>
              { result.kind === 'specialist' && (
                <span className="truncate">
                  {result.company_name}
                </span>
              )}
              {result.parent && (
                <div>
                  {'Belongs to: ' }
                  {result.parent.company_name}
                </div>
              )}
              <div className="relaxed">
                { result.specialties.concat(result.diagnostic_groups)
                  .map((s) => <Label basic className="specialty" key={s.id}>{s.name}</Label>)}
                {
                  result.no_physical_location
                  && <div className="notice">No Physical Location</div>
                }
                { result.address && (
                  <div>
                    {`${result.address.address1}, `}
                    {result.address.address2 && `${result.address.address2}`}
                    { result.address.address2 && ', '}
                    {`${result.address.city}, ${result.address.state.short_name}
                      ${result.address.zip}`}
                  </div>
                )}
                { result.phone_numbers.map((p) => (
                  <span key={p.id}>
                    {Case.title(p.kind)}
                    {': '}
                    {format(p.number, 'US', 'National')}
                    {p.ext && `#${p.ext}`}
                  </span>
                ))}
                { result.npi && (
                  <div>
                    NPI:
                    {result.npi}
                  </div>
                )}
              </div>
            </Grid.Column>
            <Grid.Column width={3}>
              <Grid.Row>
                { (isInactive(result.status) || result.custom_warning) && warningsField(result) }
                {result.is_quality
                  && (
                    <Label className="specialty" color="blue" size="small">
                      <Icon>
                        <FontAwesomeIcon icon={faAward} />
                      </Icon>
                      Recognized for Quality
                    </Label>
                  )}
              </Grid.Row>
              <Grid.Row>
                {result.has_preferred_access
                  && (
                  <Label className="specialty" color="blue" size="small">
                    <Icon>
                      <FontAwesomeIcon icon={faDoorOpen} />
                    </Icon>
                    Preferred Access
                  </Label>
                  )}
              </Grid.Row>
              <Grid.Row>
                {result.is_electronically_integrated
                  && (
                  <Label className="specialty" color="blue" size="small">
                    <Icon>
                      <FontAwesomeIcon icon={faLaptopMedical} />
                    </Icon>
                    Electronic Integration
                  </Label>
                  )}
              </Grid.Row>
              <div className="relaxed">
                <Ratings
                  availability={result.availability}
                  expertise={result.expertise}
                  personality={result.personality}
                />
              </div>
            </Grid.Column>
            <Grid.Column className="insurances" width={5}>
              <div>
                {
                  !result.accepts_insurance
                  && <div className="notice">Does Not Accept Insurance</div>
                }
                { result.contact_insurances.map((ci, index) => (
                  <span key={ci.id}>
                    {ci.insurance_carrier.name}
                    {ci.insurance_types.length > 0 && '('}
                    {ci.insurance_types.map((it) => it.name).join(', ')}
                    {ci.insurance_types.length > 0 && ')'}
                    {result.contact_insurances.length - 1 !== index && ', '}
                  </span>
                ))}
              </div>
              <div>
                { result.insurance_networks.map((insurance, index) => (
                  <span key={insurance.id}>
                    {insurance.name}
                    {result.insurance_networks.length - 1 !== index && ', '}
                  </span>
                ))}
              </div>

              <Segment basic size="tiny">
                {`Last updated ${moment(result.updated_at).fromNow()} by `}
                { result.updated_by ? result.updated_by.display_name : 'an unknown user' }
                { !result.is_complete && (
                  <>
                    ,
                    <span className="warning"> Incomplete</span>
                  </>
                )}
              </Segment>
            </Grid.Column>
          </Grid>
        </List.Content>
      </List.Item>
    ))}
  </List>
);
