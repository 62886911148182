import React from 'react';
import PropTypes from 'prop-types';
import './Logo.css';

const Logo = ({ title }) => (
  <svg className="Logo" viewBox="0 0 92.1 92.1">
    <title>{title}</title>
    { /* eslint-disable-next-line max-len */ }
    <path d="M11.5,34.6A11.42,11.42,0,0,0,0,46,11.65,11.65,0,0,0,11.5,57.6,11.59,11.59,0,0,0,23.1,46.1h0A11.59,11.59,0,0,0,11.5,34.6Zm34.6,0A11.5,11.5,0,1,0,57.6,46.1h0A11.5,11.5,0,0,0,46.1,34.6Zm0,34.5A11.5,11.5,0,1,0,57.6,80.6h0A11.57,11.57,0,0,0,46.1,69.1ZM46.1,0A11.5,11.5,0,1,0,57.6,11.5h0A11.57,11.57,0,0,0,46.1,0ZM80.6,34.6A11.5,11.5,0,1,0,92.1,46.1,11.5,11.5,0,0,0,80.6,34.6Z" />
  </svg>
);

Logo.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Logo;
