import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import qs from 'qs';
import { Grid, Loader } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import TasksNavigation from './TasksNavigation';
import Elasticsearch from '../components/Elasticsearch';
import SkippableSubscription from '../components/SkippableSubscription';
import roles from '../data/roles';
import taskCategories from '../data/taskCategories';
import tags from '../data/tags';
import taskPageSize from '../data/taskPageSize';
import Scoreboard from './ScoreBoard';
import TaskQueue from './TaskQueue';
import TasksList from './TasksList';
import './TasksPage.css';
import '../components/hoverable.css';
import TasksListHeader from './TasksListHeader';

const getProfile = gql`
  query Profile {
    profile {
      id
      default_office { id }
      service_area {
        id
        service_area_group {
          id
        }
      }
      roles {
        id
        assignable
      }
      prescribing_credentials {
        service_area {
          id
        }
      }
    }
  }
`;

const notificationSubscription = gql`
    subscription {
      notification(resource: "Todo") {
        payload
      }
    }
  `;

const constantScore = (filter, boost) => (
  { constant_score: { filter, boost } }
);

const roleBased = [
  {
    role: roles.BILLING_ADMIN,
    field: 'category_id',
    values: [taskCategories.BILLING],
    type: 'should',
    boost: 55,
  },
  {
    role: roles.ADMIN_TRAINEE,
    field: 'category_id',
    values: [taskCategories.BILLING, taskCategories.PROCEDURE_ORDER, taskCategories.TEST_ORDER],
    type: 'must_not',
    boost: 75,
  },
  {
    role: roles.MEDICAL_RECORDS_ADMIN,
    field: 'category_id',
    values: [taskCategories.MEDICAL_RECORDS_REQUEST],
    type: 'should',
    boost: 55,
  },
  {
    role: roles.AUTHS_ADMIN,
    field: 'category_id',
    values: [taskCategories.CONSULT_ORDER, taskCategories.PROCEDURE_ORDER,
      taskCategories.TEST_ORDER, taskCategories.RX_PRIOR_AUTH],
    type: 'should',
    boost: 55,
  },
  {
    role: roles.FRONT_DESK_ADMIN,
    field: 'category_id',
    values: [taskCategories.CONVERSATION, taskCategories.PRINT_AT, taskCategories.RX_RENEWAL_FAILED_ROUTING,
      taskCategories.UPDATE_PATIENT_INSURANCE],
    type: 'should',
    boost: 30,
  },
  {
    role: roles.FRONT_DESK_ADMIN,
    field: 'category_id',
    values: [taskCategories.PROCEDURE_ORDER, taskCategories.TEST_ORDER],
    type: 'must_not',
    boost: 75,
  },
  {
    role: roles.FRONT_DESK_ADMIN,
    field: 'tags.id',
    values: [tags.PHONE],
    type: 'filter',
  },
  {
    role: roles.VMT,
    field: 'category_id',
    values: [taskCategories.TREAT_ME_NOW, taskCategories.TRIAGE_ENCOUNTER],
    type: 'should',
    boost: 50,
  },
  {
    role: roles.VMT_FOCUS,
    field: 'category_id',
    values: [taskCategories.CONVERSATION],
    type: 'should',
    boost: 30,
  },
  {
    role: roles.VMT_FOCUS,
    field: 'category_id',
    values: [taskCategories.RX_RENEWAL_FAX, taskCategories.RX_RENEWAL_MYONE, taskCategories.RX_RENEWAL_PHONE,
      taskCategories.RX_RENEWAL_MOBILE, taskCategories.RX_RENEWAL_ERX, taskCategories.RX_RENEWAL_PATIENT],
    type: 'should',
    boost: 35,
  },
  {
    role: roles.PEDIATRICS,
    expression: {
      range: {
        'patient.dob': {
          lt: moment().subtract(18, 'years').utc().format(),
        },
      },
    },
    type: 'filter',
  },
];

class TasksPage extends Component {
  state = { chosenSort: 'score' }

  buildBuckets = (profile, otherQueueID = '') => {
    const assignableRoles = profile.roles.filter((role) => role.assignable);
    const isAdmin = assignableRoles.some((role) => role.id === roles.ADMIN);
    const assignedToMyRole = {
      bool: {
        should: assignableRoles.map((role) => (constantScore({ term: { assignee_id: role.id } }))),
      },
    };
    const assignedToMe = constantScore({ term: { assignee_id: profile.id } });
    const chartAccessedByMe = constantScore({ term: { chart_accessed_by_id: profile.id } }, 33);
    const isQueued = constantScore({ term: { state: 'queued' } }, 10);
    const isAssigned = constantScore({ term: { state: 'assigned' } }, 10);
    const limitedToServiceAreas = {
      bool: {
        should: profile.prescribing_credentials
          .map((cred) => constantScore({ term: { 'patient.service_area_id': cred.service_area.id } })),
      },
    };
    const isWaiting = constantScore({ term: { state: 'waiting' } });
    const isDeferred = constantScore({ term: { state: 'deferred' } });
    const isNotification = constantScore({ term: { type: 'Notification' } });
    const inMyOffice = (profile.default_office && profile.default_office.id)
      && constantScore({ term: { 'patient.office_id': profile.default_office.id } }, 25);
    const inMyServiceArea = constantScore({ term: { 'patient.service_area_id': profile.service_area.id } }, 70);
    const printAtInMyOffice = (profile.default_office && profile.default_office.id) && {
      bool: {
        should: [{
          bool: {
            must: [
              { term: { category_id: taskCategories.PRINT_AT } },
              { term: { 'patient.office_id': profile.default_office.id } },
            ],
          },
        },
        {
          bool: {
            must_not: {
              term: { category_id: taskCategories.PRINT_AT },
            },
          },
        }],
        minimum_should_match: 1,
      },
    };
    const inMyServiceAreaGroup = isAdmin ? constantScore(
      { term: { 'patient.service_area_group_id': profile.service_area.service_area_group.id } },
      40,
    ) : { exists: { field: 'patient.service_area_group_id' } };
    const noServiceArea = {
      bool: {
        must_not: constantScore({ exists: { field: 'patient.service_area_id' } }),
        boost: 39,
      },
    };
    const isHighPriority = constantScore({ term: { priority: 'high' } }, 35);
    const boostedForMyRole = roleBased.reduce((acc, mapping) => {
      if (mapping.boost && profile.roles.find((role) => role.id === mapping.role)) {
        acc.push({
          bool: {
            [mapping.type]: mapping.values.map((value) => (constantScore({ term: { [mapping.field]: value } }))),
            boost: mapping.boost,
          },
        });
      }
      return acc;
    }, []);
    const hiddenForMyRole = roleBased.reduce((acc, mapping) => {
      if (mapping.type === 'filter' && profile.roles.find((role) => role.id === mapping.role)) {
        if (mapping.values) {
          mapping.values.forEach((value) => acc.push(constantScore({ term: { [mapping.field]: value } })));
        } else {
          acc.push(mapping.expression);
        }
      }
      return acc;
    }, []);
    const assigneeMatchesMySpecialtyAdminRole = assignableRoles.reduce((acc, role) => {
      const isRoleSpecialtyQueue = role.id !== roles.ADMIN;
      if (isRoleSpecialtyQueue && isAdmin) {
        acc.push(constantScore({ term: { assignee_id: role.id } }, 55));
      }
      return acc;
    }, []);
    const slaDueDateProximity = {
      weight: 100,
      linear: {
        sla_due_at: {
          origin: moment().add(-30, 'days').utc().format(),
          offset: '30d',
          scale: '1d',
        },
      },
    };
    const outOfSlaWeight = {
      script_score: {
        script: {
          params: { now: new Date().toISOString() },
          source: `
            if (doc['sla'].value > 0) {
              Instant now = Instant.parse(params.now);
              Instant due = Instant.ofEpochMilli(doc['sla_due_at'].value.millis);
              long diff =  ChronoUnit.HOURS.between(due, now);
              return diff > 0 ? diff / doc['sla'].value : 0;
            }
          `,
        },
      },
    };

    const sortOptions = {
      score: [
        '_score',
        { priority: { order: 'asc' } },
        { state_transitioned_at: { order: 'asc' } },
      ],
      display_category: [
        { display_category: { order: 'asc' } },
        { priority: { order: 'asc' } },
        { updated_at: { order: 'asc' } },
      ],
    };

    const getUserQueueQuery = (userId, bucketName) => ({
      query: {
        bool: {
          must: constantScore({ term: { assignee_id: userId } }),
          must_not: [isWaiting, isDeferred, isNotification],
          should: [isQueued, isAssigned],
        },
      },
      aggregate: {
        bool: {
          must: constantScore({ term: { assignee_id: userId } }),
          must_not: [isWaiting, isDeferred, isNotification],
        },
      },
      sort: bucketName === 'mine' ? sortOptions[this.state.chosenSort] : sortOptions.score,
      paginated: true,
    });

    const buckets = {
      mine: getUserQueueQuery(profile.id, 'mine'),
      waiting: {
        query: {
          bool: {
            must: [
              assignedToMe,
              {
                bool: {
                  should: [isWaiting, isDeferred],
                },
              },
            ],
            must_not: [isNotification],
          },
        },
        aggregate: {
          bool: {
            must: [
              assignedToMe,
              {
                bool: {
                  should: [isWaiting, isDeferred],
                },
              },
            ],
            must_not: [isNotification],
          },
        },
        sort: [
          { priority: { order: 'asc' } },
          { state_transitioned_at: { order: 'asc' } },
        ],
        paginated: true,
      },
      notifications: {
        query: {
          bool: {
            must: [assignedToMe, isNotification],
          },
        },
        aggregate: {
          bool: {
            must: [assignedToMe, isNotification],
          },
        },
        sort: [
          { priority: { order: 'asc' } },
          { state_transitioned_at: { order: 'asc' } },
        ],
        paginated: true,
      },
    };

    if (otherQueueID.length > 0) {
      buckets.other = getUserQueueQuery(otherQueueID, 'other');
    }

    if (assignableRoles.length > 0) {
      buckets.suggested = {
        query: {
          function_score: {
            boost_mode: 'sum',
            score_mode: 'sum',
            query: {
              bool: {
                must: [
                  assignedToMyRole,
                  limitedToServiceAreas,
                  {
                    bool: {
                      should: [inMyOffice, inMyServiceArea, inMyServiceAreaGroup, noServiceArea, isHighPriority],
                    },
                  },
                ],
                should: [chartAccessedByMe, ...boostedForMyRole, ...assigneeMatchesMySpecialtyAdminRole],
                must_not: [isNotification, isDeferred, ...hiddenForMyRole],
                filter: printAtInMyOffice,
              },
            },
            functions: [slaDueDateProximity, outOfSlaWeight],
          },
        },
        aggregate: {
          bool: {
            must: [
              assignedToMyRole,
              limitedToServiceAreas,
              {
                bool: {
                  should: [inMyOffice, inMyServiceArea, inMyServiceAreaGroup, noServiceArea, isHighPriority],
                },
              },
            ],
            must_not: [isNotification, isDeferred, ...hiddenForMyRole],
          },
        },
        paginated: false,
      };
      buckets.all = {
        query: {
          function_score: {
            boost_mode: 'sum',
            score_mode: 'sum',
            query: {
              bool: {
                must: [assignedToMyRole, limitedToServiceAreas],
                must_not: [isDeferred],
                should: [
                  ...[inMyOffice, inMyServiceArea, inMyServiceAreaGroup, noServiceArea, isHighPriority],
                  ...boostedForMyRole, ...assigneeMatchesMySpecialtyAdminRole,
                ],
              },
            },
            functions: [slaDueDateProximity, outOfSlaWeight],
          },
        },
        aggregate: {
          bool: {
            must: [assignedToMyRole, limitedToServiceAreas],
            must_not: [isDeferred],
          },
        },
        paginated: true,
      };
    }

    return buckets;
  };

  generateTasksQuery = (page, allBuckets, selectedBucket) => {
    const aggs = Object.keys(allBuckets).reduce((acc, key) => {
      if (allBuckets[key].aggregate) acc[key] = { filter: allBuckets[key].aggregate };
      return acc;
    }, {});
    const { query, sort } = selectedBucket;

    return {
      index: `${process.env.REACT_APP_ELASTICSEARCH_INDEX_PREFIX || ''}todos`,
      size: taskPageSize,
      ...selectedBucket.paginated && { from: page ? (page - 1) * taskPageSize : 0 },
      search_type: 'dfs_query_then_fetch',
      body: { aggs: { global: { global: {}, aggs } }, query, sort },
    };
  };

  viewAnotherQueue = (selection, history) => {
    const queueID = _.split(selection.key, '-')[1];

    history.replace(`/tasks/other/${queueID}/${selection.title}`);
  };

  onTaskListSort = (selection, history) => {
    history.replace('/tasks/mine/');
    this.setState({
      chosenSort: selection,
    });
  };

  render() {
    const { match, location, history } = this.props;
    return (
      <Query query={getProfile}>
        {({ loading, data }) => {
          if (loading) return <Loader active />;
          const { page } = qs.parse(location.search, { ignoreQueryPrefix: true });
          const selectedBucketName = match.params.bucket;
          const otherQueueID = selectedBucketName === 'other' ? match.params.id : '';
          const buckets = this.buildBuckets(data.profile, otherQueueID, this.state.chosenSort);
          const selectedBucket = buckets[selectedBucketName];
          const tasksQuery = this.generateTasksQuery(page, buckets, selectedBucket);

          return (
            <>
              <SkippableSubscription
                subscription={notificationSubscription}
                skip={!process.env.REACT_APP_APPSYNC_API_KEY}
              >
                {({ data: subscriptionEvent }) => (
                  <Elasticsearch
                    query={tasksQuery}
                    refreshIf={(prevQuery, query, prevResults) => {
                      // ignore functions based on current time
                      if (!_.isEqual(
                        _.omit(prevQuery, 'body.query.function_score.functions'),
                        _.omit(query, 'body.query.function_score.functions'),
                      )) return true;

                      if (subscriptionEvent) {
                        const updatedTask = JSON.parse(subscriptionEvent.notification.payload);
                        const prevTask = prevResults.find((t) => t.id === updatedTask.id);
                        return prevTask && (prevTask.updated_at !== updatedTask.updated_at);
                      }

                      return false;
                    }}
                  >
                    {({ searching, results: tasks, total, aggregations, refresh }) => (
                      <Grid className="TasksPage">
                        <Grid.Column width={3}>
                          <TasksNavigation
                            buckets={buckets}
                            aggregations={aggregations}
                            onViewAnotherQueue={(selection) => this.viewAnotherQueue(selection, history)}
                          />
                        </Grid.Column>
                        <Grid.Column width={10}>
                          <TasksListHeader
                            selectedBucketName={selectedBucketName}
                            refresh={refresh}
                            match={match}
                            onSort={(selection) => this.onTaskListSort(selection, history)}
                          />
                          <TasksList
                            match={match}
                            location={location}
                            history={history}
                            searching={searching}
                            tasks={tasks}
                            total={total}
                            refresh={refresh}
                            buckets={buckets}
                          />
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <>
                            <TaskQueue />
                            <Scoreboard />
                          </>
                        </Grid.Column>
                      </Grid>
                    )}
                  </Elasticsearch>
                )}
              </SkippableSubscription>
            </>
          );
        }}
      </Query>
    );
  }
}

export default TasksPage;
