
const mergeOwnAndInheritedDedupedByKind = (allKinds, inheritedValues, myOwnValues) => {
  const inheritedKinds = (inheritedValues || []).reduce((kinds, value) => kinds.concat([value.kind]), []);
  const markDuplicateKindsToDestroy = (values) => (
    values.map((value) => {
      if (inheritedKinds.includes(value.kind)) {
        // Mark duplicate kinds for deletion on the back end
        return Object.assign(value, { _destroy: true });
      }
      return value;
    })
  );
  const dedupedValues = inheritedKinds.length > 0 ? markDuplicateKindsToDestroy(myOwnValues) : myOwnValues;

  return dedupedValues.filter((p) => !p._destroy).concat(inheritedValues || []);
};

export default mergeOwnAndInheritedDedupedByKind;
