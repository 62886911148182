import React from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';

const InheritedAttribute = (props) => (
  <FormSpy
    render={({ values }) => {
      // This is different than parent being null if you've never set it before
      const parentWasRemoved = !!values.parent && Object.keys(values.parent).length === 0;
      // If you explicitly remove the parent in the form, OR
      // if you select a new parent that has empty values for a given property,
      // that section of the form should be editable
      const parentHasAttr = !!values.parent && Object.keys(values.parent).includes(props.name);
      const parentHasEmptyAttr = parentHasAttr && !props.booleanAttribute
        && (!values.parent[props.name] || Object.keys(values.parent[props.name]).length === 0);

      const hasParentWithAttr = !parentWasRemoved && parentHasAttr && !parentHasEmptyAttr;

      return props.children({
        disabled: hasParentWithAttr,
        inheritedValue: hasParentWithAttr ? values.parent && values.parent[props.name] : null,
        parent: hasParentWithAttr ? values.parent : false,
      });
    }}
  />
);

InheritedAttribute.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InheritedAttribute;
