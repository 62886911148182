import React from 'react';
import PropTypes from 'prop-types';
import { Header, Grid } from 'semantic-ui-react';
import './Section.css';

const Section = ({ title, right, children }) => (
  <div className="Section">
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <Header as="h2">{title}</Header>
        </Grid.Column>
        { right && (
          <Grid.Column textAlign="right">
            { right }
          </Grid.Column>
        )}
      </Grid.Row>
      { children }
    </Grid>
  </div>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  right: PropTypes.node,
};

export default Section;
