import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Grid, Search } from 'semantic-ui-react';
import { FormSpy } from 'react-final-form';
import uniqid from 'uniqid';
import _ from 'lodash';
import GooglePlaces from '../components/GooglePlaces';
import ShowHide from '../components/ShowHide';
import Section from './Section';
import PhoneNumbersTable from './PhoneNumbersTable';
import EmailAddressesTable from './EmailAddressesTable';
import Field from '../components/Field';
import { compose, any, presence, taxIdFormat, usState, digits } from './Validators';
import InheritedAttribute from './InheritedAttribute';
import InheritanceLockIcon from './InheritanceLockIcon';
import './ContactInformationSection.css';

const getInstitutions = gql`
  query {
    institutions { id, name }
  }
`;

class ContactInformationSection extends Component {
  state = {}

  handleGoogleAddressSearch = (event) => {
    this.setState({ googleAddress: event.target.value });
  }

  handleGoogleAddressSelect = (event, { result }, form) => {
    this.setState({ googleAddress: result.description, loadingPlace: true });
    (new window.google.maps.places.PlacesService(event.target))
      .getDetails({ placeId: result.placeid }, (place, status) => {
        if (status !== 'OK') {
          console.error(new Error(`Google Places request failure: ${status}`)); // eslint-disable-line no-console
        }
        const parts = (place.address_components || [])
          .reduce((acc, entry) => { acc[entry.types[0]] = entry; return acc; }, {});
        form.change('addressId', null);
        form.change('address1', [(parts.street_number || {}).long_name, (parts.route || {}).long_name].join(' '));
        form.change('address2', (parts.subpremise || {}).long_name || '');
        form.change('city', (parts.locality || {}).long_name || '');
        form.change('state', (parts.administrative_area_level_1 || {}).short_name || '');
        form.change('zip', (parts.postal_code || {}).short_name || '');
        this.setState({ loadingPlace: false });
      });
  }

  render() {
    const { contactKind, isContactAdmin } = this.props;
    return (
      <div className="ContactInformationSection">
        <Section title="Contact Information">
          <Grid.Row>
            <Grid.Column>
              <PhoneNumbersTable isContactAdmin={isContactAdmin} />
            </Grid.Column>
          </Grid.Row>

          <FormSpy>
            {({
              form, values: {
                is_partner, emails_attributes, website, office_hours, institution_ids, tax_id,
              },
            }) => {
              const cannotEditPartner = is_partner && !isContactAdmin;

              return (
                <>
                  <InheritedAttribute name="no_physical_location" booleanAttribute>
                    {({
                      parent: noPhysicalLocationParent,
                      disabled: disabledNoPhysicalLocation,
                      inheritedValue: inheritedNoPhysicalLocation,
                    }) => (
                      <>
                        <Grid.Row>
                          <Grid.Column className="no-physical-location-checkbox">
                            <Field.Checkbox
                              name="no_physical_location"
                              label="No Physical Location"
                              toggle
                              value={inheritedNoPhysicalLocation}
                              disabled={cannotEditPartner || disabledNoPhysicalLocation}
                              data-cy="contact-no-physical-location-checkbox"
                            />
                            {noPhysicalLocationParent && <InheritanceLockIcon parent={noPhysicalLocationParent} />}
                          </Grid.Column>
                        </Grid.Row>
                        <FormSpy>
                          {({ values: { no_physical_location } }) => (
                            <InheritedAttribute name="address">
                              {({ disabled, inheritedValue: inheritedAddress, parent }) => (
                                <>
                                  { !disabled && !cannotEditPartner && !(no_physical_location
                                  || inheritedNoPhysicalLocation) && (
                                    <Grid.Row>
                                      <Grid.Column>
                                        <GooglePlaces input={this.state.googleAddress}>
                                          {({ results, searching }) => (
                                            <Search
                                              input={{ fluid: true, type: 'search' }}
                                              placeholder="Search for address or business name"
                                              fluid
                                              loading={searching}
                                              value={this.state.googleAddress}
                                              showNoResults={false}
                                              onSearchChange={
                                                _.debounce(this.handleGoogleAddressSearch, 500, { leading: true })
                                              }
                                              results={results.map(({ description, highlight, placeid }) => ({
                                                key: uniqid(),
                                                title: '',
                                                description,
                                                highlight,
                                                placeid,
                                              }))}
                                              onResultSelect={
                                                (event, data) => this.handleGoogleAddressSelect(event, data, form)
                                              }
                                              resultRenderer={({ highlight }) => (
                                                <div dangerouslySetInnerHTML={{__html: highlight}}/> //eslint-disable-line
                                              )}
                                              data-cy="contact-address-input"
                                            />
                                          )}
                                        </GooglePlaces>
                                      </Grid.Column>
                                    </Grid.Row>
                                  )}
                                  { (disabled || !(no_physical_location || inheritedNoPhysicalLocation)) && (
                                    <>
                                      <Grid.Row>
                                        <Grid.Column>
                                          <Field.Input
                                            name="address1"
                                            label="Street Address"
                                            placeholder="Street and number"
                                            maxLength={100}
                                            loading={this.state.loadingPlace}
                                            required={contactKind !== 'group'}
                                            value={inheritedAddress && inheritedAddress.address1}
                                            disabled={cannotEditPartner || disabled}
                                            validate={contactKind !== 'group'
                                              ? any(presence, () => presence(inheritedAddress
                                                && inheritedAddress.address1)) : null}
                                            icon={disabled && inheritedAddress
                                              ? <InheritanceLockIcon parent={parent} popupHorizontalOffset={-5} />
                                              : null}
                                            data-cy="contact-address-one-input"
                                          />
                                          <Field.Input
                                            name="address2"
                                            placeholder="Suite, unit, building, floor, etc."
                                            maxLength={100}
                                            value={inheritedAddress && inheritedAddress.address2}
                                            disabled={cannotEditPartner || disabled}
                                            loading={this.state.loadingPlace}
                                            icon={disabled && inheritedAddress
                                              ? <InheritanceLockIcon parent={parent} popupHorizontalOffset={-5} />
                                              : null}
                                            data-cy="contact-address-two-input"
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column>
                                          <Field.Input
                                            name="cross_street"
                                            label="Cross Street"
                                            maxLength={100}
                                            value={inheritedAddress && inheritedAddress.cross_street}
                                            disabled={cannotEditPartner || disabled}
                                            icon={disabled && inheritedAddress ? (
                                              <InheritanceLockIcon parent={parent} popupHorizontalOffset={-5} />
                                            ) : null}
                                            data-cy="contact-address-two-input"
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row columns={3}>
                                        <Grid.Column>
                                          <Field.Input
                                            name="city"
                                            label="City"
                                            maxLength={50}
                                            required={contactKind !== 'group'}
                                            validate={contactKind !== 'group'
                                              ? any(presence, () => presence(inheritedAddress
                                                && inheritedAddress.city)) : null}
                                            value={inheritedAddress && inheritedAddress.city}
                                            disabled={cannotEditPartner || disabled}
                                            icon={disabled && inheritedAddress
                                              ? <InheritanceLockIcon parent={parent} /> : null}
                                            data-cy="contact-address-city-input"
                                          />
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field.Input
                                            name="state"
                                            label="State"
                                            maxLength={2}
                                            format={(toFormat) => (toFormat ? toFormat.toUpperCase() : '')}
                                            required={contactKind !== 'group'}
                                            value={inheritedAddress
                                              && (inheritedAddress.state.short_name ? inheritedAddress.state.short_name
                                                : inheritedAddress.state)}
                                            disabled={cannotEditPartner || disabled}
                                            validate={contactKind !== 'group'
                                              ? compose(any(presence, () => presence(inheritedAddress
                                                && inheritedAddress.state)), usState) : null}
                                            icon={disabled && inheritedAddress
                                              ? <InheritanceLockIcon parent={parent} popupVerticalOffset={-5} /> : null}
                                            data-cy="contact-address-state-input"
                                          />
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field.Input
                                            name="zip"
                                            label="Zip Code"
                                            required={contactKind !== 'group'}
                                            value={inheritedAddress && inheritedAddress.zip}
                                            disabled={cannotEditPartner || disabled}
                                            validate={contactKind !== 'group'
                                              ? compose(any(presence, () => presence(inheritedAddress
                                                && inheritedAddress.zip)), digits(5, 'Invalid zip code')) : null}
                                            icon={disabled && inheritedAddress ? (
                                              <InheritanceLockIcon
                                                parent={parent}
                                                popupVerticalOffset={-5}
                                                popupHorizontalOffset={-5}
                                              />
                                            ) : null}
                                            data-cy="contact-address-zip-input"
                                          />
                                        </Grid.Column>
                                      </Grid.Row>
                                    </>
                                  )}
                                </>
                              )}
                            </InheritedAttribute>
                          )}
                        </FormSpy>
                      </>
                    )}
                  </InheritedAttribute>

                  <InheritedAttribute name="emails">
                    {({ inheritedValue: inheritedEmails, parent }) => (
                      <ShowHide title="Add email addresses" active={(inheritedEmails || emails_attributes).length}>
                        <Grid columns="equal" padded="vertically">
                          <Grid.Row>
                            <Grid.Column>
                              <EmailAddressesTable
                                disabled={cannotEditPartner}
                                inheritedEmails={inheritedEmails}
                                parent={parent}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </ShowHide>
                    )}
                  </InheritedAttribute>

                  <ShowHide
                    title="Add office information"
                    active={website || office_hours || institution_ids.length || tax_id}
                  >
                    <Grid columns="equal" padded="vertically">
                      <Grid.Row>
                        <Grid.Column>
                          <Field.Input
                            name="website"
                            label="Website"
                            maxLength={128}
                            disabled={cannotEditPartner}
                            data-cy="contact-website-input"
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Field.Input
                            name="office_hours"
                            label="Office Hours"
                            maxLength={64}
                            disabled={cannotEditPartner}
                            data-cy="contact-office-hours-input"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Query query={getInstitutions}>
                            {({ loading, data: { institutions } }) => (
                              <Field.Dropdown
                                name="institution_ids"
                                label="Institutions Relationships"
                                search
                                multiple
                                openOnFocus={false}
                                loading={loading}
                                options={(institutions || []).map((i) => ({ key: i.id, text: i.name, value: i.id }))}
                                disabled={cannotEditPartner}
                                data-cy="contact-insitutions-relationships-dropdown"
                              />
                            )}
                          </Query>
                        </Grid.Column>
                        <Grid.Column>
                          <Field.FormattedNumber
                            name="tax_id"
                            label="Tax ID"
                            format="##-#######"
                            validate={taxIdFormat}
                            disabled={cannotEditPartner}
                            data-cy="contact-tax-id-input"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </ShowHide>
                </>
              );
            }}
          </FormSpy>
        </Section>
      </div>
    );
  }
}

ContactInformationSection.propTypes = {
  contactKind: PropTypes.string.isRequired,
};

export default ContactInformationSection;
